* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.display-1, .display-2, .display-3, .display-4 {
  font-family: $font-family-display;
  font-weight: bold;

  @media screen and (max-width: breakpoint-max(sm)) {
    font-size: $display4-size * 0.75;
  }
}

.font-family-sans-serif {
  font-family: $font-family-sans-serif;
}

.font-family-display {
  font-family: $font-family-display;
}

.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}

.p-small {
  font-size: $font-size-sm;
}

.label {
  font-family: $font-family-sans-serif;
  color: $dark;
  letter-spacing: 1.6px;
  line-height: 22px;
  text-transform: uppercase;
}