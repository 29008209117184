.commerce {
  .order-item {
    position: relative;
    padding-bottom: map_get($spacers, 3);

    &:not(:last-of-type) {
      border-bottom: 2px solid $light;
      margin-bottom: map_get($spacers, 3);
    }

    .remove-item-form:not(.remove-item-form--btn-link) {
      position: absolute;
      top: 0;
      right: 0;
    }

    .remove-item-form--btn-link {
      .btn-link {
        font-weight: 600;
      }
    }

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: transparentize($mid-grey, 0.5);
      }
    }
  }

  .checkout-progress {
    .progress-indicator {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 60px;
      text-align: center;
      font-size: $font-size-base * 2;
      border-radius: 50%;
      opacity: 0.4;
      transition: 0.2s;

      @media screen and (max-width: breakpoint-max(sm)) {
        height: 40px;
        width: 40px;
        font-size: $font-size-base * 1.5;
      }

      &:not(:last-of-type) {
        margin-right: map_get($spacers, 1);
      }

      &.active {
        opacity: 1;
        @include box-shadow(4);
      }
    }
  }

  .item-summary-mobile {
    border-radius: $brand-border-radius;

    @media screen and (min-width: breakpoint-min(lg)) {
      display: none !important;
    }

    .item-thumbnail {
      width: 60px;
      height: 60px;
    }

    .remove-item-form {
      position: relative;
    }

    .pricing-totals {
      .pricing-total {
        display: flex;
        justify-content: space-between;
      }

      .shipping-total {
        font-size: $h5-font-size;
      }

      .grand-total {
        font-size: $h5-font-size;
        font-weight: bold;
      }
    }
  }

  .additional-information {
    border: 2px solid $mid-grey;
  }

  .order-summary {
    .order-section__details {
      p {
        margin-bottom: map_get($spacers, 2);
      }
    }
  }

  .additional-riders {
    .additional-rider-details {
      &:not(:first-of-type) {
        padding-top: map_get($spacers, 4);
      }
    }
  }

  #payment-card-form {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 220px;
    padding: map_get($spacers, 4);
    border-radius: 10px;
    color: $white;
    background-color: $primary;
    @include box-shadow(8);

    @media screen and (max-width: breakpoint-max(sm)) {
      width: calc(100% + (2 * #{map_get($spacers, 3)}));
      margin-left: map_get($spacers, -3);
      height: 0;
      padding-bottom: 60%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: transparentize($primary, 0.5);
      z-index: 0;
    }

    .fmt-icon {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 180px;
      height: auto;
      z-index: 1;

      @media screen and (max-width: breakpoint-max(sm)) {
        top: 15px;
        left: 15px;
        width: 140px;
        height: auto;
      }
    }

    .card-errors {
      width: calc(100% - 100px);
    }

    .card-element {
      position: absolute;
      top: 70%;
      left: 50%;
      width: calc(100% - (#{map_get($spacers, 3)} * 2));
      height: auto;
      transform: translate(-50%, -50%);
      -webkit-font-smoothing: antialiased !important;
    }
  }
}
