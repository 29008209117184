.cart-summary {
  &--mobile {
    @media screen and (max-width: breakpoint-max(md)) {
      display: none !important;
    }
  }

  .item-thumbnail {
    width: 60px;
    height: 60px;
  }

  .pricing-totals {
    .pricing-total {
      display: flex;
      justify-content: space-between;
    }

    .shipping-total {
      font-size: $h1-font-size;
    }

    .grand-total {
      color: $slate-grey;
      font-size: $h1-font-size;
      font-weight: bold;
    }
  }
}