main.faqs {
  .faq-category {
    &:not(:first-child) {
      margin-top: map_get($spacers, 5);
    }

    &:last-child {
      margin-bottom: map_get($spacers, 5);
    }

    .faq-item {
      border: 0;
      border-bottom: 1px solid transparentize($slate-grey, 0.8);
      border-radius: 0;

      .card-header {
        background-color: transparent;
        border: 0;
        border-radius: 0;

        button {
          font-size: $font-size-lg;

          i {
            transition: 0.2s;
          }

          &[aria-expanded=true] {
            i {
              color: $primary !important;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}