#global-footer {
  a {
    text-decoration: none;
  }

  .footer-brand {
    max-width: 140px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .footer__nav {
    li {
      a {
        color: $white;
      }
    }

    .nav__sub-list {
      min-width: 140px;
    }
  }

  .footer__socials {
    min-width: 100px;

    .social-account {
      &:not(:first-of-type) {
        margin-left: map_get($spacers, 3);
      }
    }
  }

  .container--copyright {
    font-size: $small-font-size;

    a {
      color: $white;
    }
  }

  .footer__euphoriq {
    img {
      width: auto;
      height: 14px;
    }
  }
}