.fmt-list {
  li, div {
    position: relative;
    list-style: none;
    padding: map_get($spacers, 2) 0;

    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      top: calc(#{map_get($spacers, 3)} + 5px);
      left: -1.1225em;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $secondary;
      transform: translateY(-50%);
    }
  }
}