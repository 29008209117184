.hero {
  .hero__content, .item__content {
    width: 50%;

    > h1 {
      text-shadow: $emphasis-text-shadow-display;
    }

    > p {
      line-height: 1.4;
      text-shadow: $emphasis-text-shadow;
    }

    @media screen and (max-width: breakpoint-max(xs)) {
      width: calc(100% - (2 * #{map_get($spacers, 4)}));

      h1 {
        font-size: $h1-font-size;
      }

      p {
        font-size: $font-size-base;
      }
    }

    @media screen and (min-width: breakpoint-min(sm)) and (max-width: breakpoint-max(lg)) {
      width: calc(75% - #{map_get($spacers, 4)});

      h1 {
        font-size: $h1-font-size * 2;
      }
    }
  }
}


.hero--header {
  position: relative;
  height: 500px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $secondary;
    background: linear-gradient(0deg, rgba(33,37,41,0.8) 0%, rgba(33,37,41,0) 60%);
    z-index: 0;
  }

  .hero__content {
    position: absolute;
    bottom: map_get($spacers, 3);
    left: map_get($spacers, 3);
    z-index: 1;
  }
}

.hero--carousel {
  height: calc(100vh - #{$navbar-height});
  min-height: 400px;
  max-height: 720px;

  @media screen and (max-width: breakpoint-max(sm)) {
    height: calc(60vh - #{$navbar-height-mobile});
    max-height: 550px;
  }

  .carousel-item {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(30,87,153,0) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,0.5) 100%);
      z-index: 0;
    }

    .item__content {
      position: absolute;
      bottom: map_get($spacers, 5);
      left: map_get($spacers, 5);
      height: auto;
      z-index: 1;

      @media screen and (max-width: breakpoint-max(sm)) {
        bottom: calc(#{map_get($spacers, 5)} + #{map_get($spacers, 3)});
        left: map_get($spacers, 4);
      }
    }
  }
}

.carousel-indicators {
  justify-content: flex-end;
  margin-right: map_get($spacers, 5);
  margin-bottom: map_get($spacers, 5);

  @media screen and (max-width: breakpoint-max(sm)) {
    justify-content: flex-start;
    margin-left: map_get($spacers, 4);
    margin-right: 0;
    margin-bottom: map_get($spacers, 4);
  }

  li {
    width: 12px;
    height: 12px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid $white;

    &.active {
      height: 16px;
      width: 16px;
      background-color: $white;
      border: none;
    }
  }
}

.hero--product-showcase {
  position: relative;
  // Hide sidebar shadow sitting over proceeding markup
  overflow-y: hidden;

  @media screen and (min-width: breakpoint-min(lg)) {
    height: calc(80vh - 82px);
    min-height: 650px;
    max-height: 800px;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100px;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.75) 95%);
    }
  }

  @media screen and (max-width: breakpoint-max(md)) {
    min-height: 300px !important;
  }

  .hero-sidebar {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 100%;
    box-shadow: 9px 10px 30px 0px rgba(0,0,0,0.65);
    background-color: $white;
    transition: 0.5s;
    z-index: 2 !important;

    @media screen and (max-width: breakpoint-max(md)) {
      position: relative;
      width: 100% !important;
    }

    .showcase-body {
      z-index: 2 !important;

      + .sidebar-close {
        position: absolute;
        top: 50%;
        right: 1px;
        height: 48px;
        width: 30px;
        border-width: 0;
        border-radius: 0 $btn-border-radius $btn-border-radius 0;
        background-color: $white;
        transform: translate(100%, -50%);
        z-index: 1 !important;

        @media screen and (max-width: breakpoint-max(md)) {
          display: none;
        }
      }
    }
  }

  .sidebar-open {
    display: none;
    position: absolute;
    left: map_get($spacers, 5);
    bottom: map_get($spacers, 5);
    z-index: 1 !important;
    transition: 0.4s;

    &.active {
      display: block;
    }
  }

  .owl-carousel__container {
    position: relative;
    display: flex;

    @media screen and (max-width: breakpoint-max(md)) {
      height: 400px !important;
    }

    @media screen and (max-width: breakpoint-max(xs)) {
      height: 260px !important;
    }

    .owl-carousel__indicators {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      bottom: map_get($spacers, 3);
      right: 0;
      z-index: 1;

      @media screen and (max-width: breakpoint-max(sm)) {
        left: 0;
        bottom: 0;
      }

      button {
        appearance: none;
        display: inline-block;
        width: 16px;
        height: 16px;
        padding: 0 !important;
        margin-right: map_get($spacers, 1);
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid $white;

        @media screen and (max-width: breakpoint-max(sm)) {
          width: 10px;
          height: 10px;
        }

        &.active {
          background-color: $white;
        }
      }
    }

    .hero-carousel {
      position: relative;
      width: 100%;
      z-index: 0;

      &::before {
        content: '';
        position: absolute;
        width: 300px;
        height: 300px;
        top: 50%;
        left: 50%;
        background-image: url('../img/fmt-logo-red.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        opacity: 0.5;
        transform: translate(-50%, -50%);
      }

      .owl-stage-outer {
        &, * {
          height: 100%;
        }
      }

      .carousel-slide {
        height: 100%;

        @media screen and (max-width: breakpoint-max(md)) {
          max-height: 400px !important;
        }
      }
    }
  }

  .showcase__details {
    .badge {
      padding: map_get($spacers, 2) map_get($spacers, 3);
      color: $white;

      &.badge__duration {
        background-color: transparentize($primary, 0.4);
      }

      &.badge__skill {
        background-color: $primary;
      }
    }
  }
}

main.commerce, main.formThanks {
  display: flex;

  .hero--order, .hero--thanks {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba(0,1,17,0.3) 0%,rgba(125,185,232,0) 50%);
      z-index: 0;
    }

    .hero__content {
      z-index: 1;

      i {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
      }
    }
  }
}