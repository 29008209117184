/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #BA1F33;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #5FAD56;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFFFFF;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #005979;
  --secondary: #e44a50;
  --light: #FAFAFA;
  --dark: #212529;
  --success: #5FAD56;
  --danger: #BA1F33;
  --alert: #BA1F33;
  --midnight-green: #005979;
  --rose: #F45B69;
  --fmt-red: #DA2128;
  --red-lighten: #e44a50;
  --mid-grey: #D8D8D8;
  --slate-grey: #212529;
  --primary: #005979;
  --secondary: #e44a50;
  --success: #5FAD56;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #BA1F33;
  --light: #FAFAFA;
  --dark: #212529;
  --alert: #BA1F33;
  --midnight-green: #005979;
  --rose: #F45B69;
  --green: #5FAD56;
  --red: #BA1F33;
  --white: #FFFFFF;
  --slate-grey: #212529;
  --slate-grey-opaque: rgba(33, 37, 41, 0.1);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FFFFFF; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #005979;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #00212d;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  line-height: 1; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 500; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 2px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 2px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 4px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 4px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 2px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 2px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 4px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d1d9; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7aa9b9; }

.table-hover .table-primary:hover {
  background-color: #a7c6d0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a7c6d0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f7ccce; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f1a1a4; }

.table-hover .table-secondary:hover {
  background-color: #f4b6b9; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f4b6b9; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d2e8d0; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #acd4a7; }

.table-hover .table-success:hover {
  background-color: #c2e0bf; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #c2e0bf; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ecc0c6; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #db8b95; }

.table-hover .table-danger:hover {
  background-color: #e6acb4; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e6acb4; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfc; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c2c3; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8c8e90; }

.table-hover .table-dark:hover {
  background-color: #b4b5b6; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b4b5b6; }

.table-alert,
.table-alert > th,
.table-alert > td {
  background-color: #ecc0c6; }

.table-alert th,
.table-alert td,
.table-alert thead th,
.table-alert tbody + tbody {
  border-color: #db8b95; }

.table-hover .table-alert:hover {
  background-color: #e6acb4; }
  .table-hover .table-alert:hover > td,
  .table-hover .table-alert:hover > th {
    background-color: #e6acb4; }

.table-midnight-green,
.table-midnight-green > th,
.table-midnight-green > td {
  background-color: #b8d1d9; }

.table-midnight-green th,
.table-midnight-green td,
.table-midnight-green thead th,
.table-midnight-green tbody + tbody {
  border-color: #7aa9b9; }

.table-hover .table-midnight-green:hover {
  background-color: #a7c6d0; }
  .table-hover .table-midnight-green:hover > td,
  .table-hover .table-midnight-green:hover > th {
    background-color: #a7c6d0; }

.table-rose,
.table-rose > th,
.table-rose > td {
  background-color: #fcd1d5; }

.table-rose th,
.table-rose td,
.table-rose thead th,
.table-rose tbody + tbody {
  border-color: #f9aab1; }

.table-hover .table-rose:hover {
  background-color: #fab9bf; }
  .table-hover .table-rose:hover > td,
  .table-hover .table-rose:hover > th {
    background-color: #fab9bf; }

.table-green,
.table-green > th,
.table-green > td {
  background-color: #d2e8d0; }

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #acd4a7; }

.table-hover .table-green:hover {
  background-color: #c2e0bf; }
  .table-hover .table-green:hover > td,
  .table-hover .table-green:hover > th {
    background-color: #c2e0bf; }

.table-red,
.table-red > th,
.table-red > td {
  background-color: #ecc0c6; }

.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody + tbody {
  border-color: #db8b95; }

.table-hover .table-red:hover {
  background-color: #e6acb4; }
  .table-hover .table-red:hover > td,
  .table-hover .table-red:hover > th {
    background-color: #e6acb4; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-slate-grey,
.table-slate-grey > th,
.table-slate-grey > td {
  background-color: #c1c2c3; }

.table-slate-grey th,
.table-slate-grey td,
.table-slate-grey thead th,
.table-slate-grey tbody + tbody {
  border-color: #8c8e90; }

.table-hover .table-slate-grey:hover {
  background-color: #b4b5b6; }
  .table-hover .table-slate-grey:hover > td,
  .table-hover .table-slate-grey:hover > th {
    background-color: #b4b5b6; }

.table-slate-grey-opaque,
.table-slate-grey-opaque > th,
.table-slate-grey-opaque > td {
  background-color: rgba(251, 251, 251, 0.748); }

.table-slate-grey-opaque th,
.table-slate-grey-opaque td,
.table-slate-grey-opaque thead th,
.table-slate-grey-opaque tbody + tbody {
  border-color: rgba(243, 243, 243, 0.532); }

.table-hover .table-slate-grey-opaque:hover {
  background-color: rgba(238, 238, 238, 0.748); }
  .table-hover .table-slate-grey-opaque:hover > td,
  .table-hover .table-slate-grey-opaque:hover > th {
    background-color: rgba(238, 238, 238, 0.748); }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #FFFFFF;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 2px solid #dee2e6;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #00b7f9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #FFFFFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #5FAD56; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: rgba(95, 173, 86, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #5FAD56;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235FAD56' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #5FAD56;
    box-shadow: 0 0 0 0.2rem rgba(95, 173, 86, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #5FAD56;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235FAD56' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #FFFFFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #5FAD56;
    box-shadow: 0 0 0 0.2rem rgba(95, 173, 86, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #5FAD56; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #5FAD56; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #5FAD56; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #80be78;
  background-color: #80be78; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(95, 173, 86, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #5FAD56; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #5FAD56; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #5FAD56;
  box-shadow: 0 0 0 0.2rem rgba(95, 173, 86, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #BA1F33; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: rgba(186, 31, 51, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #BA1F33;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23BA1F33' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23BA1F33' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #BA1F33;
    box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #BA1F33;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23BA1F33' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23BA1F33' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #FFFFFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #BA1F33;
    box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #BA1F33; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #BA1F33; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #BA1F33; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #dc3046;
  background-color: #dc3046; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #BA1F33; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #BA1F33; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #BA1F33;
  box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #FFFFFF;
  background-color: #005979;
  border-color: #005979; }
  .btn-primary:hover {
    color: #FFFFFF;
    background-color: #003d53;
    border-color: #003346; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 114, 141, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFFFFF;
    background-color: #005979;
    border-color: #005979; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #003346;
    border-color: #002a39; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 114, 141, 0.5); }

.btn-secondary {
  color: #FFFFFF;
  background-color: #e44a50;
  border-color: #e44a50; }
  .btn-secondary:hover {
    color: #FFFFFF;
    background-color: #df2930;
    border-color: #da2128; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 101, 106, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFFFFF;
    background-color: #e44a50;
    border-color: #e44a50; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #da2128;
    border-color: #cf1f26; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(232, 101, 106, 0.5); }

.btn-success {
  color: #FFFFFF;
  background-color: #5FAD56;
  border-color: #5FAD56; }
  .btn-success:hover {
    color: #FFFFFF;
    background-color: #509548;
    border-color: #4b8c44; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 185, 111, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFFFFF;
    background-color: #5FAD56;
    border-color: #5FAD56; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #4b8c44;
    border-color: #478340; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 185, 111, 0.5); }

.btn-info {
  color: #FFFFFF;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #FFFFFF;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #FFFFFF;
  background-color: #BA1F33;
  border-color: #BA1F33; }
  .btn-danger:hover {
    color: #FFFFFF;
    background-color: #991a2a;
    border-color: #8e1827; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #8e1827;
    border-color: #831624; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }

.btn-light {
  color: #212529;
  background-color: #FAFAFA;
  border-color: #FAFAFA; }
  .btn-light:hover {
    color: #212529;
    background-color: #e7e7e7;
    border-color: #e1e1e1; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e1e1e1;
    border-color: #dadada; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5); }

.btn-dark {
  color: #FFFFFF;
  background-color: #212529;
  border-color: #212529; }
  .btn-dark:hover {
    color: #FFFFFF;
    background-color: #101214;
    border-color: #0a0c0d; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFFFFF;
    background-color: #212529;
    border-color: #212529; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0a0c0d;
    border-color: #050506; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }

.btn-alert {
  color: #FFFFFF;
  background-color: #BA1F33;
  border-color: #BA1F33; }
  .btn-alert:hover {
    color: #FFFFFF;
    background-color: #991a2a;
    border-color: #8e1827; }
  .btn-alert:focus, .btn-alert.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }
  .btn-alert.disabled, .btn-alert:disabled {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
  .btn-alert:not(:disabled):not(.disabled):active, .btn-alert:not(:disabled):not(.disabled).active,
  .show > .btn-alert.dropdown-toggle {
    color: #FFFFFF;
    background-color: #8e1827;
    border-color: #831624; }
    .btn-alert:not(:disabled):not(.disabled):active:focus, .btn-alert:not(:disabled):not(.disabled).active:focus,
    .show > .btn-alert.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }

.btn-midnight-green {
  color: #FFFFFF;
  background-color: #005979;
  border-color: #005979; }
  .btn-midnight-green:hover {
    color: #FFFFFF;
    background-color: #003d53;
    border-color: #003346; }
  .btn-midnight-green:focus, .btn-midnight-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 114, 141, 0.5); }
  .btn-midnight-green.disabled, .btn-midnight-green:disabled {
    color: #FFFFFF;
    background-color: #005979;
    border-color: #005979; }
  .btn-midnight-green:not(:disabled):not(.disabled):active, .btn-midnight-green:not(:disabled):not(.disabled).active,
  .show > .btn-midnight-green.dropdown-toggle {
    color: #FFFFFF;
    background-color: #003346;
    border-color: #002a39; }
    .btn-midnight-green:not(:disabled):not(.disabled):active:focus, .btn-midnight-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-midnight-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 114, 141, 0.5); }

.btn-rose {
  color: #FFFFFF;
  background-color: #F45B69;
  border-color: #F45B69; }
  .btn-rose:hover {
    color: #FFFFFF;
    background-color: #f23748;
    border-color: #f12b3d; }
  .btn-rose:focus, .btn-rose.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 116, 128, 0.5); }
  .btn-rose.disabled, .btn-rose:disabled {
    color: #FFFFFF;
    background-color: #F45B69;
    border-color: #F45B69; }
  .btn-rose:not(:disabled):not(.disabled):active, .btn-rose:not(:disabled):not(.disabled).active,
  .show > .btn-rose.dropdown-toggle {
    color: #FFFFFF;
    background-color: #f12b3d;
    border-color: #f01f32; }
    .btn-rose:not(:disabled):not(.disabled):active:focus, .btn-rose:not(:disabled):not(.disabled).active:focus,
    .show > .btn-rose.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 116, 128, 0.5); }

.btn-green {
  color: #FFFFFF;
  background-color: #5FAD56;
  border-color: #5FAD56; }
  .btn-green:hover {
    color: #FFFFFF;
    background-color: #509548;
    border-color: #4b8c44; }
  .btn-green:focus, .btn-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 185, 111, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #FFFFFF;
    background-color: #5FAD56;
    border-color: #5FAD56; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #FFFFFF;
    background-color: #4b8c44;
    border-color: #478340; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 185, 111, 0.5); }

.btn-red {
  color: #FFFFFF;
  background-color: #BA1F33;
  border-color: #BA1F33; }
  .btn-red:hover {
    color: #FFFFFF;
    background-color: #991a2a;
    border-color: #8e1827; }
  .btn-red:focus, .btn-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }
  .btn-red.disabled, .btn-red:disabled {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
  .btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active,
  .show > .btn-red.dropdown-toggle {
    color: #FFFFFF;
    background-color: #8e1827;
    border-color: #831624; }
    .btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }

.btn-white {
  color: #212529;
  background-color: #FFFFFF;
  border-color: #FFFFFF; }
  .btn-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-slate-grey {
  color: #FFFFFF;
  background-color: #212529;
  border-color: #212529; }
  .btn-slate-grey:hover {
    color: #FFFFFF;
    background-color: #101214;
    border-color: #0a0c0d; }
  .btn-slate-grey:focus, .btn-slate-grey.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-slate-grey.disabled, .btn-slate-grey:disabled {
    color: #FFFFFF;
    background-color: #212529;
    border-color: #212529; }
  .btn-slate-grey:not(:disabled):not(.disabled):active, .btn-slate-grey:not(:disabled):not(.disabled).active,
  .show > .btn-slate-grey.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0a0c0d;
    border-color: #050506; }
    .btn-slate-grey:not(:disabled):not(.disabled):active:focus, .btn-slate-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-slate-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }

.btn-slate-grey-opaque {
  color: #FFFFFF;
  background-color: rgba(33, 37, 41, 0.1);
  border-color: rgba(33, 37, 41, 0.1); }
  .btn-slate-grey-opaque:hover {
    color: #FFFFFF;
    background-color: rgba(16, 18, 20, 0.1);
    border-color: rgba(10, 12, 13, 0.1); }
  .btn-slate-grey-opaque:focus, .btn-slate-grey-opaque.focus {
    box-shadow: 0 0 0 0.2rem rgba(204, 205, 206, 0.5); }
  .btn-slate-grey-opaque.disabled, .btn-slate-grey-opaque:disabled {
    color: #FFFFFF;
    background-color: rgba(33, 37, 41, 0.1);
    border-color: rgba(33, 37, 41, 0.1); }
  .btn-slate-grey-opaque:not(:disabled):not(.disabled):active, .btn-slate-grey-opaque:not(:disabled):not(.disabled).active,
  .show > .btn-slate-grey-opaque.dropdown-toggle {
    color: #FFFFFF;
    background-color: rgba(10, 12, 13, 0.1);
    border-color: rgba(5, 5, 6, 0.1); }
    .btn-slate-grey-opaque:not(:disabled):not(.disabled):active:focus, .btn-slate-grey-opaque:not(:disabled):not(.disabled).active:focus,
    .show > .btn-slate-grey-opaque.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(204, 205, 206, 0.5); }

.btn-outline-primary {
  color: #005979;
  border-color: #005979; }
  .btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #005979;
    border-color: #005979; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #005979;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #005979;
    border-color: #005979; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.5); }

.btn-outline-secondary {
  color: #e44a50;
  border-color: #e44a50; }
  .btn-outline-secondary:hover {
    color: #FFFFFF;
    background-color: #e44a50;
    border-color: #e44a50; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(228, 74, 80, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #e44a50;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #e44a50;
    border-color: #e44a50; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(228, 74, 80, 0.5); }

.btn-outline-success {
  color: #5FAD56;
  border-color: #5FAD56; }
  .btn-outline-success:hover {
    color: #FFFFFF;
    background-color: #5FAD56;
    border-color: #5FAD56; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(95, 173, 86, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #5FAD56;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #5FAD56;
    border-color: #5FAD56; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(95, 173, 86, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #BA1F33;
  border-color: #BA1F33; }
  .btn-outline-danger:hover {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #BA1F33;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.5); }

.btn-outline-light {
  color: #FAFAFA;
  border-color: #FAFAFA; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FAFAFA;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-outline-dark {
  color: #212529;
  border-color: #212529; }
  .btn-outline-dark:hover {
    color: #FFFFFF;
    background-color: #212529;
    border-color: #212529; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #212529;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #212529;
    border-color: #212529; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.btn-outline-alert {
  color: #BA1F33;
  border-color: #BA1F33; }
  .btn-outline-alert:hover {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
  .btn-outline-alert:focus, .btn-outline-alert.focus {
    box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.5); }
  .btn-outline-alert.disabled, .btn-outline-alert:disabled {
    color: #BA1F33;
    background-color: transparent; }
  .btn-outline-alert:not(:disabled):not(.disabled):active, .btn-outline-alert:not(:disabled):not(.disabled).active,
  .show > .btn-outline-alert.dropdown-toggle {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
    .btn-outline-alert:not(:disabled):not(.disabled):active:focus, .btn-outline-alert:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-alert.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.5); }

.btn-outline-midnight-green {
  color: #005979;
  border-color: #005979; }
  .btn-outline-midnight-green:hover {
    color: #FFFFFF;
    background-color: #005979;
    border-color: #005979; }
  .btn-outline-midnight-green:focus, .btn-outline-midnight-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.5); }
  .btn-outline-midnight-green.disabled, .btn-outline-midnight-green:disabled {
    color: #005979;
    background-color: transparent; }
  .btn-outline-midnight-green:not(:disabled):not(.disabled):active, .btn-outline-midnight-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-midnight-green.dropdown-toggle {
    color: #FFFFFF;
    background-color: #005979;
    border-color: #005979; }
    .btn-outline-midnight-green:not(:disabled):not(.disabled):active:focus, .btn-outline-midnight-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-midnight-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.5); }

.btn-outline-rose {
  color: #F45B69;
  border-color: #F45B69; }
  .btn-outline-rose:hover {
    color: #FFFFFF;
    background-color: #F45B69;
    border-color: #F45B69; }
  .btn-outline-rose:focus, .btn-outline-rose.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 91, 105, 0.5); }
  .btn-outline-rose.disabled, .btn-outline-rose:disabled {
    color: #F45B69;
    background-color: transparent; }
  .btn-outline-rose:not(:disabled):not(.disabled):active, .btn-outline-rose:not(:disabled):not(.disabled).active,
  .show > .btn-outline-rose.dropdown-toggle {
    color: #FFFFFF;
    background-color: #F45B69;
    border-color: #F45B69; }
    .btn-outline-rose:not(:disabled):not(.disabled):active:focus, .btn-outline-rose:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-rose.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 91, 105, 0.5); }

.btn-outline-green {
  color: #5FAD56;
  border-color: #5FAD56; }
  .btn-outline-green:hover {
    color: #FFFFFF;
    background-color: #5FAD56;
    border-color: #5FAD56; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(95, 173, 86, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #5FAD56;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #FFFFFF;
    background-color: #5FAD56;
    border-color: #5FAD56; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(95, 173, 86, 0.5); }

.btn-outline-red {
  color: #BA1F33;
  border-color: #BA1F33; }
  .btn-outline-red:hover {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
  .btn-outline-red:focus, .btn-outline-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.5); }
  .btn-outline-red.disabled, .btn-outline-red:disabled {
    color: #BA1F33;
    background-color: transparent; }
  .btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-red.dropdown-toggle {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
    .btn-outline-red:not(:disabled):not(.disabled):active:focus, .btn-outline-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.5); }

.btn-outline-white {
  color: #FFFFFF;
  border-color: #FFFFFF; }
  .btn-outline-white:hover {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #FFFFFF;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-slate-grey {
  color: #212529;
  border-color: #212529; }
  .btn-outline-slate-grey:hover {
    color: #FFFFFF;
    background-color: #212529;
    border-color: #212529; }
  .btn-outline-slate-grey:focus, .btn-outline-slate-grey.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }
  .btn-outline-slate-grey.disabled, .btn-outline-slate-grey:disabled {
    color: #212529;
    background-color: transparent; }
  .btn-outline-slate-grey:not(:disabled):not(.disabled):active, .btn-outline-slate-grey:not(:disabled):not(.disabled).active,
  .show > .btn-outline-slate-grey.dropdown-toggle {
    color: #FFFFFF;
    background-color: #212529;
    border-color: #212529; }
    .btn-outline-slate-grey:not(:disabled):not(.disabled):active:focus, .btn-outline-slate-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-slate-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.btn-outline-slate-grey-opaque {
  color: rgba(33, 37, 41, 0.1);
  border-color: rgba(33, 37, 41, 0.1); }
  .btn-outline-slate-grey-opaque:hover {
    color: #FFFFFF;
    background-color: rgba(33, 37, 41, 0.1);
    border-color: rgba(33, 37, 41, 0.1); }
  .btn-outline-slate-grey-opaque:focus, .btn-outline-slate-grey-opaque.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }
  .btn-outline-slate-grey-opaque.disabled, .btn-outline-slate-grey-opaque:disabled {
    color: rgba(33, 37, 41, 0.1);
    background-color: transparent; }
  .btn-outline-slate-grey-opaque:not(:disabled):not(.disabled):active, .btn-outline-slate-grey-opaque:not(:disabled):not(.disabled).active,
  .show > .btn-outline-slate-grey-opaque.dropdown-toggle {
    color: #FFFFFF;
    background-color: rgba(33, 37, 41, 0.1);
    border-color: rgba(33, 37, 41, 0.1); }
    .btn-outline-slate-grey-opaque:not(:disabled):not(.disabled):active:focus, .btn-outline-slate-grey-opaque:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-slate-grey-opaque.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.btn-link {
  font-weight: 400;
  color: #005979;
  text-decoration: none; }
  .btn-link:hover {
    color: #00212d;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #005979; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -2px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -2px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -2px; }

.input-group-prepend {
  margin-right: -2px; }

.input-group-append {
  margin-left: -2px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 2px solid #dee2e6;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 4px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 4px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFFFFF;
    border-color: #005979;
    background-color: #005979; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #00b7f9; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #2dc7ff;
    border-color: #2dc7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #FFFFFF;
    border: #adb5bd solid 2px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #005979;
  background-color: #005979; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFFFFF' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 89, 121, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 89, 121, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 89, 121, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 4px);
    left: calc(-2.25rem + 4px);
    width: calc(1rem - 8px);
    height: calc(1rem - 8px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #FFFFFF;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 89, 121, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #FFFFFF;
  border: 2px solid #dee2e6;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #00b7f9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #FFFFFF; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 4px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 4px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 4px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #00b7f9;
    box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 4px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFFFFF;
  border: 2px solid #dee2e6;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 89, 121, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 89, 121, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.2rem rgba(0, 89, 121, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #005979;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #2dc7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #005979;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #2dc7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #005979;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #2dc7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 2px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -2px; }
  .nav-tabs .nav-link {
    border: 2px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #dee2e6 #dee2e6 #FFFFFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #005979; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: -4.25rem;
  padding-bottom: -4.25rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 5px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.75rem;
        padding-left: 0.75rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFFFFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFFFFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFFFFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFFFFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 2px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 2px) calc(0.25rem - 2px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 2px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 2px) calc(0.25rem - 2px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 2px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 2px);
  border-top-right-radius: calc(0.25rem - 2px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 2px);
  border-bottom-left-radius: calc(0.25rem - 2px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -2px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -2px;
  line-height: 1.25;
  color: #005979;
  background-color: #FFFFFF;
  border: 2px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #00212d;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFFFFF;
  background-color: #005979;
  border-color: #005979; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFFFFF;
  background-color: #005979; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFFFFF;
    background-color: #003346; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.5); }

.badge-secondary {
  color: #FFFFFF;
  background-color: #e44a50; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFFFFF;
    background-color: #da2128; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(228, 74, 80, 0.5); }

.badge-success {
  color: #FFFFFF;
  background-color: #5FAD56; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFFFFF;
    background-color: #4b8c44; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(95, 173, 86, 0.5); }

.badge-info {
  color: #FFFFFF;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #FFFFFF;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #FFFFFF;
  background-color: #BA1F33; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFFFFF;
    background-color: #8e1827; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.5); }

.badge-light {
  color: #212529;
  background-color: #FAFAFA; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #e1e1e1; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.badge-dark {
  color: #FFFFFF;
  background-color: #212529; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFFFFF;
    background-color: #0a0c0d; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.badge-alert {
  color: #FFFFFF;
  background-color: #BA1F33; }
  a.badge-alert:hover, a.badge-alert:focus {
    color: #FFFFFF;
    background-color: #8e1827; }
  a.badge-alert:focus, a.badge-alert.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.5); }

.badge-midnight-green {
  color: #FFFFFF;
  background-color: #005979; }
  a.badge-midnight-green:hover, a.badge-midnight-green:focus {
    color: #FFFFFF;
    background-color: #003346; }
  a.badge-midnight-green:focus, a.badge-midnight-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 89, 121, 0.5); }

.badge-rose {
  color: #FFFFFF;
  background-color: #F45B69; }
  a.badge-rose:hover, a.badge-rose:focus {
    color: #FFFFFF;
    background-color: #f12b3d; }
  a.badge-rose:focus, a.badge-rose.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 91, 105, 0.5); }

.badge-green {
  color: #FFFFFF;
  background-color: #5FAD56; }
  a.badge-green:hover, a.badge-green:focus {
    color: #FFFFFF;
    background-color: #4b8c44; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(95, 173, 86, 0.5); }

.badge-red {
  color: #FFFFFF;
  background-color: #BA1F33; }
  a.badge-red:hover, a.badge-red:focus {
    color: #FFFFFF;
    background-color: #8e1827; }
  a.badge-red:focus, a.badge-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(186, 31, 51, 0.5); }

.badge-white {
  color: #212529;
  background-color: #FFFFFF; }
  a.badge-white:hover, a.badge-white:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-slate-grey {
  color: #FFFFFF;
  background-color: #212529; }
  a.badge-slate-grey:hover, a.badge-slate-grey:focus {
    color: #FFFFFF;
    background-color: #0a0c0d; }
  a.badge-slate-grey:focus, a.badge-slate-grey.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.badge-slate-grey-opaque {
  color: #FFFFFF;
  background-color: rgba(33, 37, 41, 0.1); }
  a.badge-slate-grey-opaque:hover, a.badge-slate-grey-opaque:focus {
    color: #FFFFFF;
    background-color: rgba(10, 12, 13, 0.1); }
  a.badge-slate-grey-opaque:focus, a.badge-slate-grey-opaque.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #002e3f;
  background-color: #ccdee4;
  border-color: #b8d1d9; }
  .alert-primary hr {
    border-top-color: #a7c6d0; }
  .alert-primary .alert-link {
    color: #00090c; }

.alert-secondary {
  color: #76272a;
  background-color: #fadbdc;
  border-color: #f7ccce; }
  .alert-secondary hr {
    border-top-color: #f4b6b9; }
  .alert-secondary .alert-link {
    color: #501a1c; }

.alert-success {
  color: #315a2d;
  background-color: #dfefdd;
  border-color: #d2e8d0; }
  .alert-success hr {
    border-top-color: #c2e0bf; }
  .alert-success .alert-link {
    color: #1e381c; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #61101b;
  background-color: #f1d2d6;
  border-color: #ecc0c6; }
  .alert-danger hr {
    border-top-color: #e6acb4; }
  .alert-danger .alert-link {
    color: #35090f; }

.alert-light {
  color: #828282;
  background-color: #fefefe;
  border-color: #fefefe; }
  .alert-light hr {
    border-top-color: #f1f1f1; }
  .alert-light .alert-link {
    color: dimgray; }

.alert-dark {
  color: #111315;
  background-color: #d3d3d4;
  border-color: #c1c2c3; }
  .alert-dark hr {
    border-top-color: #b4b5b6; }
  .alert-dark .alert-link {
    color: black; }

.alert-alert {
  color: #61101b;
  background-color: #f1d2d6;
  border-color: #ecc0c6; }
  .alert-alert hr {
    border-top-color: #e6acb4; }
  .alert-alert .alert-link {
    color: #35090f; }

.alert-midnight-green {
  color: #002e3f;
  background-color: #ccdee4;
  border-color: #b8d1d9; }
  .alert-midnight-green hr {
    border-top-color: #a7c6d0; }
  .alert-midnight-green .alert-link {
    color: #00090c; }

.alert-rose {
  color: #7f2f37;
  background-color: #fddee1;
  border-color: #fcd1d5; }
  .alert-rose hr {
    border-top-color: #fab9bf; }
  .alert-rose .alert-link {
    color: #5a2127; }

.alert-green {
  color: #315a2d;
  background-color: #dfefdd;
  border-color: #d2e8d0; }
  .alert-green hr {
    border-top-color: #c2e0bf; }
  .alert-green .alert-link {
    color: #1e381c; }

.alert-red {
  color: #61101b;
  background-color: #f1d2d6;
  border-color: #ecc0c6; }
  .alert-red hr {
    border-top-color: #e6acb4; }
  .alert-red .alert-link {
    color: #35090f; }

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

.alert-slate-grey {
  color: #111315;
  background-color: #d3d3d4;
  border-color: #c1c2c3; }
  .alert-slate-grey hr {
    border-top-color: #b4b5b6; }
  .alert-slate-grey .alert-link {
    color: black; }

.alert-slate-grey-opaque {
  color: rgba(2, 2, 2, 0.532);
  background-color: rgba(252, 252, 252, 0.82);
  border-color: rgba(251, 251, 251, 0.748); }
  .alert-slate-grey-opaque hr {
    border-top-color: rgba(238, 238, 238, 0.748); }
  .alert-slate-grey-opaque .alert-link {
    color: rgba(0, 0, 0, 0.532); }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #005979;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -2px;
  background-color: #FFFFFF;
  border: 2px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #FFFFFF; }
  .list-group-item.active {
    z-index: 2;
    color: #FFFFFF;
    background-color: #005979;
    border-color: #005979; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -2px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -2px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -2px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -2px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -2px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -2px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #002e3f;
  background-color: #b8d1d9; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #002e3f;
    background-color: #a7c6d0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #002e3f;
    border-color: #002e3f; }

.list-group-item-secondary {
  color: #76272a;
  background-color: #f7ccce; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #76272a;
    background-color: #f4b6b9; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #76272a;
    border-color: #76272a; }

.list-group-item-success {
  color: #315a2d;
  background-color: #d2e8d0; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #315a2d;
    background-color: #c2e0bf; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #315a2d;
    border-color: #315a2d; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #61101b;
  background-color: #ecc0c6; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #61101b;
    background-color: #e6acb4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #61101b;
    border-color: #61101b; }

.list-group-item-light {
  color: #828282;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #828282;
    background-color: #f1f1f1; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #828282;
    border-color: #828282; }

.list-group-item-dark {
  color: #111315;
  background-color: #c1c2c3; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #111315;
    background-color: #b4b5b6; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #111315;
    border-color: #111315; }

.list-group-item-alert {
  color: #61101b;
  background-color: #ecc0c6; }
  .list-group-item-alert.list-group-item-action:hover, .list-group-item-alert.list-group-item-action:focus {
    color: #61101b;
    background-color: #e6acb4; }
  .list-group-item-alert.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #61101b;
    border-color: #61101b; }

.list-group-item-midnight-green {
  color: #002e3f;
  background-color: #b8d1d9; }
  .list-group-item-midnight-green.list-group-item-action:hover, .list-group-item-midnight-green.list-group-item-action:focus {
    color: #002e3f;
    background-color: #a7c6d0; }
  .list-group-item-midnight-green.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #002e3f;
    border-color: #002e3f; }

.list-group-item-rose {
  color: #7f2f37;
  background-color: #fcd1d5; }
  .list-group-item-rose.list-group-item-action:hover, .list-group-item-rose.list-group-item-action:focus {
    color: #7f2f37;
    background-color: #fab9bf; }
  .list-group-item-rose.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7f2f37;
    border-color: #7f2f37; }

.list-group-item-green {
  color: #315a2d;
  background-color: #d2e8d0; }
  .list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
    color: #315a2d;
    background-color: #c2e0bf; }
  .list-group-item-green.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #315a2d;
    border-color: #315a2d; }

.list-group-item-red {
  color: #61101b;
  background-color: #ecc0c6; }
  .list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
    color: #61101b;
    background-color: #e6acb4; }
  .list-group-item-red.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #61101b;
    border-color: #61101b; }

.list-group-item-white {
  color: #858585;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-slate-grey {
  color: #111315;
  background-color: #c1c2c3; }
  .list-group-item-slate-grey.list-group-item-action:hover, .list-group-item-slate-grey.list-group-item-action:focus {
    color: #111315;
    background-color: #b4b5b6; }
  .list-group-item-slate-grey.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #111315;
    border-color: #111315; }

.list-group-item-slate-grey-opaque {
  color: rgba(2, 2, 2, 0.532);
  background-color: rgba(251, 251, 251, 0.748); }
  .list-group-item-slate-grey-opaque.list-group-item-action:hover, .list-group-item-slate-grey-opaque.list-group-item-action:focus {
    color: rgba(2, 2, 2, 0.532);
    background-color: rgba(238, 238, 238, 0.748); }
  .list-group-item-slate-grey-opaque.list-group-item-action.active {
    color: #FFFFFF;
    background-color: rgba(2, 2, 2, 0.532);
    border-color: rgba(2, 2, 2, 0.532); }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 2px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 2px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 2px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 2px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FFFFFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 2px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 2px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FFFFFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 2px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 2px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FFFFFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 2px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 2px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 2px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FFFFFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 2px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 2px);
  border-top-right-radius: calc(0.3rem - 2px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFFFFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #005979 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #003346 !important; }

.bg-secondary {
  background-color: #e44a50 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #da2128 !important; }

.bg-success {
  background-color: #5FAD56 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #4b8c44 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #BA1F33 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #8e1827 !important; }

.bg-light {
  background-color: #FAFAFA !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e1e1e1 !important; }

.bg-dark {
  background-color: #212529 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0a0c0d !important; }

.bg-alert {
  background-color: #BA1F33 !important; }

a.bg-alert:hover, a.bg-alert:focus,
button.bg-alert:hover,
button.bg-alert:focus {
  background-color: #8e1827 !important; }

.bg-midnight-green {
  background-color: #005979 !important; }

a.bg-midnight-green:hover, a.bg-midnight-green:focus,
button.bg-midnight-green:hover,
button.bg-midnight-green:focus {
  background-color: #003346 !important; }

.bg-rose {
  background-color: #F45B69 !important; }

a.bg-rose:hover, a.bg-rose:focus,
button.bg-rose:hover,
button.bg-rose:focus {
  background-color: #f12b3d !important; }

.bg-green {
  background-color: #5FAD56 !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #4b8c44 !important; }

.bg-red {
  background-color: #BA1F33 !important; }

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #8e1827 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-slate-grey {
  background-color: #212529 !important; }

a.bg-slate-grey:hover, a.bg-slate-grey:focus,
button.bg-slate-grey:hover,
button.bg-slate-grey:focus {
  background-color: #0a0c0d !important; }

.bg-slate-grey-opaque {
  background-color: rgba(33, 37, 41, 0.1) !important; }

a.bg-slate-grey-opaque:hover, a.bg-slate-grey-opaque:focus,
button.bg-slate-grey-opaque:hover,
button.bg-slate-grey-opaque:focus {
  background-color: rgba(10, 12, 13, 0.1) !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 2px solid #dee2e6 !important; }

.border-top {
  border-top: 2px solid #dee2e6 !important; }

.border-right {
  border-right: 2px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 2px solid #dee2e6 !important; }

.border-left {
  border-left: 2px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #005979 !important; }

.border-secondary {
  border-color: #e44a50 !important; }

.border-success {
  border-color: #5FAD56 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #BA1F33 !important; }

.border-light {
  border-color: #FAFAFA !important; }

.border-dark {
  border-color: #212529 !important; }

.border-alert {
  border-color: #BA1F33 !important; }

.border-midnight-green {
  border-color: #005979 !important; }

.border-rose {
  border-color: #F45B69 !important; }

.border-green {
  border-color: #5FAD56 !important; }

.border-red {
  border-color: #BA1F33 !important; }

.border-white {
  border-color: #FFFFFF !important; }

.border-slate-grey {
  border-color: #212529 !important; }

.border-slate-grey-opaque {
  border-color: rgba(33, 37, 41, 0.1) !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-20 {
  width: 20% !important; }

.w-40 {
  width: 40% !important; }

.w-60 {
  width: 60% !important; }

.w-80 {
  width: 80% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-20 {
  height: 20% !important; }

.h-40 {
  height: 40% !important; }

.h-60 {
  height: 60% !important; }

.h-80 {
  height: 80% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m--1 {
  margin: -0.25rem !important; }

.mt--1,
.my--1 {
  margin-top: -0.25rem !important; }

.mr--1,
.mx--1 {
  margin-right: -0.25rem !important; }

.mb--1,
.my--1 {
  margin-bottom: -0.25rem !important; }

.ml--1,
.mx--1 {
  margin-left: -0.25rem !important; }

.m--2 {
  margin: -0.5rem !important; }

.mt--2,
.my--2 {
  margin-top: -0.5rem !important; }

.mr--2,
.mx--2 {
  margin-right: -0.5rem !important; }

.mb--2,
.my--2 {
  margin-bottom: -0.5rem !important; }

.ml--2,
.mx--2 {
  margin-left: -0.5rem !important; }

.m--3 {
  margin: -1rem !important; }

.mt--3,
.my--3 {
  margin-top: -1rem !important; }

.mr--3,
.mx--3 {
  margin-right: -1rem !important; }

.mb--3,
.my--3 {
  margin-bottom: -1rem !important; }

.ml--3,
.mx--3 {
  margin-left: -1rem !important; }

.m--4 {
  margin: -1.5rem !important; }

.mt--4,
.my--4 {
  margin-top: -1.5rem !important; }

.mr--4,
.mx--4 {
  margin-right: -1.5rem !important; }

.mb--4,
.my--4 {
  margin-bottom: -1.5rem !important; }

.ml--4,
.mx--4 {
  margin-left: -1.5rem !important; }

.m--5 {
  margin: -3rem !important; }

.mt--5,
.my--5 {
  margin-top: -3rem !important; }

.mr--5,
.mx--5 {
  margin-right: -3rem !important; }

.mb--5,
.my--5 {
  margin-bottom: -3rem !important; }

.ml--5,
.mx--5 {
  margin-left: -3rem !important; }

.m--6 {
  margin: -4.5rem !important; }

.mt--6,
.my--6 {
  margin-top: -4.5rem !important; }

.mr--6,
.mx--6 {
  margin-right: -4.5rem !important; }

.mb--6,
.my--6 {
  margin-bottom: -4.5rem !important; }

.ml--6,
.mx--6 {
  margin-left: -4.5rem !important; }

.m--7 {
  margin: -6rem !important; }

.mt--7,
.my--7 {
  margin-top: -6rem !important; }

.mr--7,
.mx--7 {
  margin-right: -6rem !important; }

.mb--7,
.my--7 {
  margin-bottom: -6rem !important; }

.ml--7,
.mx--7 {
  margin-left: -6rem !important; }

.m-6 {
  margin: 4.5rem !important; }

.mt-6,
.my-6 {
  margin-top: 4.5rem !important; }

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important; }

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important; }

.m-7 {
  margin: 6rem !important; }

.mt-7,
.my-7 {
  margin-top: 6rem !important; }

.mr-7,
.mx-7 {
  margin-right: 6rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 6rem !important; }

.ml-7,
.mx-7 {
  margin-left: 6rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p--1 {
  padding: -0.25rem !important; }

.pt--1,
.py--1 {
  padding-top: -0.25rem !important; }

.pr--1,
.px--1 {
  padding-right: -0.25rem !important; }

.pb--1,
.py--1 {
  padding-bottom: -0.25rem !important; }

.pl--1,
.px--1 {
  padding-left: -0.25rem !important; }

.p--2 {
  padding: -0.5rem !important; }

.pt--2,
.py--2 {
  padding-top: -0.5rem !important; }

.pr--2,
.px--2 {
  padding-right: -0.5rem !important; }

.pb--2,
.py--2 {
  padding-bottom: -0.5rem !important; }

.pl--2,
.px--2 {
  padding-left: -0.5rem !important; }

.p--3 {
  padding: -1rem !important; }

.pt--3,
.py--3 {
  padding-top: -1rem !important; }

.pr--3,
.px--3 {
  padding-right: -1rem !important; }

.pb--3,
.py--3 {
  padding-bottom: -1rem !important; }

.pl--3,
.px--3 {
  padding-left: -1rem !important; }

.p--4 {
  padding: -1.5rem !important; }

.pt--4,
.py--4 {
  padding-top: -1.5rem !important; }

.pr--4,
.px--4 {
  padding-right: -1.5rem !important; }

.pb--4,
.py--4 {
  padding-bottom: -1.5rem !important; }

.pl--4,
.px--4 {
  padding-left: -1.5rem !important; }

.p--5 {
  padding: -3rem !important; }

.pt--5,
.py--5 {
  padding-top: -3rem !important; }

.pr--5,
.px--5 {
  padding-right: -3rem !important; }

.pb--5,
.py--5 {
  padding-bottom: -3rem !important; }

.pl--5,
.px--5 {
  padding-left: -3rem !important; }

.p--6 {
  padding: -4.5rem !important; }

.pt--6,
.py--6 {
  padding-top: -4.5rem !important; }

.pr--6,
.px--6 {
  padding-right: -4.5rem !important; }

.pb--6,
.py--6 {
  padding-bottom: -4.5rem !important; }

.pl--6,
.px--6 {
  padding-left: -4.5rem !important; }

.p--7 {
  padding: -6rem !important; }

.pt--7,
.py--7 {
  padding-top: -6rem !important; }

.pr--7,
.px--7 {
  padding-right: -6rem !important; }

.pb--7,
.py--7 {
  padding-bottom: -6rem !important; }

.pl--7,
.px--7 {
  padding-left: -6rem !important; }

.p-6 {
  padding: 4.5rem !important; }

.pt-6,
.py-6 {
  padding-top: 4.5rem !important; }

.pr-6,
.px-6 {
  padding-right: 4.5rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important; }

.pl-6,
.px-6 {
  padding-left: 4.5rem !important; }

.p-7 {
  padding: 6rem !important; }

.pt-7,
.py-7 {
  padding-top: 6rem !important; }

.pr-7,
.px-7 {
  padding-right: 6rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 6rem !important; }

.pl-7,
.px-7 {
  padding-left: 6rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n-1 {
  margin: 0.25rem !important; }

.mt-n-1,
.my-n-1 {
  margin-top: 0.25rem !important; }

.mr-n-1,
.mx-n-1 {
  margin-right: 0.25rem !important; }

.mb-n-1,
.my-n-1 {
  margin-bottom: 0.25rem !important; }

.ml-n-1,
.mx-n-1 {
  margin-left: 0.25rem !important; }

.m-n-2 {
  margin: 0.5rem !important; }

.mt-n-2,
.my-n-2 {
  margin-top: 0.5rem !important; }

.mr-n-2,
.mx-n-2 {
  margin-right: 0.5rem !important; }

.mb-n-2,
.my-n-2 {
  margin-bottom: 0.5rem !important; }

.ml-n-2,
.mx-n-2 {
  margin-left: 0.5rem !important; }

.m-n-3 {
  margin: 1rem !important; }

.mt-n-3,
.my-n-3 {
  margin-top: 1rem !important; }

.mr-n-3,
.mx-n-3 {
  margin-right: 1rem !important; }

.mb-n-3,
.my-n-3 {
  margin-bottom: 1rem !important; }

.ml-n-3,
.mx-n-3 {
  margin-left: 1rem !important; }

.m-n-4 {
  margin: 1.5rem !important; }

.mt-n-4,
.my-n-4 {
  margin-top: 1.5rem !important; }

.mr-n-4,
.mx-n-4 {
  margin-right: 1.5rem !important; }

.mb-n-4,
.my-n-4 {
  margin-bottom: 1.5rem !important; }

.ml-n-4,
.mx-n-4 {
  margin-left: 1.5rem !important; }

.m-n-5 {
  margin: 3rem !important; }

.mt-n-5,
.my-n-5 {
  margin-top: 3rem !important; }

.mr-n-5,
.mx-n-5 {
  margin-right: 3rem !important; }

.mb-n-5,
.my-n-5 {
  margin-bottom: 3rem !important; }

.ml-n-5,
.mx-n-5 {
  margin-left: 3rem !important; }

.m-n-6 {
  margin: 4.5rem !important; }

.mt-n-6,
.my-n-6 {
  margin-top: 4.5rem !important; }

.mr-n-6,
.mx-n-6 {
  margin-right: 4.5rem !important; }

.mb-n-6,
.my-n-6 {
  margin-bottom: 4.5rem !important; }

.ml-n-6,
.mx-n-6 {
  margin-left: 4.5rem !important; }

.m-n-7 {
  margin: 6rem !important; }

.mt-n-7,
.my-n-7 {
  margin-top: 6rem !important; }

.mr-n-7,
.mx-n-7 {
  margin-right: 6rem !important; }

.mb-n-7,
.my-n-7 {
  margin-bottom: 6rem !important; }

.ml-n-7,
.mx-n-7 {
  margin-left: 6rem !important; }

.m-n6 {
  margin: -4.5rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -4.5rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -4.5rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -4.5rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -4.5rem !important; }

.m-n7 {
  margin: -6rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -6rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -6rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -6rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -6rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm--1 {
    margin: -0.25rem !important; }
  .mt-sm--1,
  .my-sm--1 {
    margin-top: -0.25rem !important; }
  .mr-sm--1,
  .mx-sm--1 {
    margin-right: -0.25rem !important; }
  .mb-sm--1,
  .my-sm--1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm--1,
  .mx-sm--1 {
    margin-left: -0.25rem !important; }
  .m-sm--2 {
    margin: -0.5rem !important; }
  .mt-sm--2,
  .my-sm--2 {
    margin-top: -0.5rem !important; }
  .mr-sm--2,
  .mx-sm--2 {
    margin-right: -0.5rem !important; }
  .mb-sm--2,
  .my-sm--2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm--2,
  .mx-sm--2 {
    margin-left: -0.5rem !important; }
  .m-sm--3 {
    margin: -1rem !important; }
  .mt-sm--3,
  .my-sm--3 {
    margin-top: -1rem !important; }
  .mr-sm--3,
  .mx-sm--3 {
    margin-right: -1rem !important; }
  .mb-sm--3,
  .my-sm--3 {
    margin-bottom: -1rem !important; }
  .ml-sm--3,
  .mx-sm--3 {
    margin-left: -1rem !important; }
  .m-sm--4 {
    margin: -1.5rem !important; }
  .mt-sm--4,
  .my-sm--4 {
    margin-top: -1.5rem !important; }
  .mr-sm--4,
  .mx-sm--4 {
    margin-right: -1.5rem !important; }
  .mb-sm--4,
  .my-sm--4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm--4,
  .mx-sm--4 {
    margin-left: -1.5rem !important; }
  .m-sm--5 {
    margin: -3rem !important; }
  .mt-sm--5,
  .my-sm--5 {
    margin-top: -3rem !important; }
  .mr-sm--5,
  .mx-sm--5 {
    margin-right: -3rem !important; }
  .mb-sm--5,
  .my-sm--5 {
    margin-bottom: -3rem !important; }
  .ml-sm--5,
  .mx-sm--5 {
    margin-left: -3rem !important; }
  .m-sm--6 {
    margin: -4.5rem !important; }
  .mt-sm--6,
  .my-sm--6 {
    margin-top: -4.5rem !important; }
  .mr-sm--6,
  .mx-sm--6 {
    margin-right: -4.5rem !important; }
  .mb-sm--6,
  .my-sm--6 {
    margin-bottom: -4.5rem !important; }
  .ml-sm--6,
  .mx-sm--6 {
    margin-left: -4.5rem !important; }
  .m-sm--7 {
    margin: -6rem !important; }
  .mt-sm--7,
  .my-sm--7 {
    margin-top: -6rem !important; }
  .mr-sm--7,
  .mx-sm--7 {
    margin-right: -6rem !important; }
  .mb-sm--7,
  .my-sm--7 {
    margin-bottom: -6rem !important; }
  .ml-sm--7,
  .mx-sm--7 {
    margin-left: -6rem !important; }
  .m-sm-6 {
    margin: 4.5rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important; }
  .m-sm-7 {
    margin: 6rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm--1 {
    padding: -0.25rem !important; }
  .pt-sm--1,
  .py-sm--1 {
    padding-top: -0.25rem !important; }
  .pr-sm--1,
  .px-sm--1 {
    padding-right: -0.25rem !important; }
  .pb-sm--1,
  .py-sm--1 {
    padding-bottom: -0.25rem !important; }
  .pl-sm--1,
  .px-sm--1 {
    padding-left: -0.25rem !important; }
  .p-sm--2 {
    padding: -0.5rem !important; }
  .pt-sm--2,
  .py-sm--2 {
    padding-top: -0.5rem !important; }
  .pr-sm--2,
  .px-sm--2 {
    padding-right: -0.5rem !important; }
  .pb-sm--2,
  .py-sm--2 {
    padding-bottom: -0.5rem !important; }
  .pl-sm--2,
  .px-sm--2 {
    padding-left: -0.5rem !important; }
  .p-sm--3 {
    padding: -1rem !important; }
  .pt-sm--3,
  .py-sm--3 {
    padding-top: -1rem !important; }
  .pr-sm--3,
  .px-sm--3 {
    padding-right: -1rem !important; }
  .pb-sm--3,
  .py-sm--3 {
    padding-bottom: -1rem !important; }
  .pl-sm--3,
  .px-sm--3 {
    padding-left: -1rem !important; }
  .p-sm--4 {
    padding: -1.5rem !important; }
  .pt-sm--4,
  .py-sm--4 {
    padding-top: -1.5rem !important; }
  .pr-sm--4,
  .px-sm--4 {
    padding-right: -1.5rem !important; }
  .pb-sm--4,
  .py-sm--4 {
    padding-bottom: -1.5rem !important; }
  .pl-sm--4,
  .px-sm--4 {
    padding-left: -1.5rem !important; }
  .p-sm--5 {
    padding: -3rem !important; }
  .pt-sm--5,
  .py-sm--5 {
    padding-top: -3rem !important; }
  .pr-sm--5,
  .px-sm--5 {
    padding-right: -3rem !important; }
  .pb-sm--5,
  .py-sm--5 {
    padding-bottom: -3rem !important; }
  .pl-sm--5,
  .px-sm--5 {
    padding-left: -3rem !important; }
  .p-sm--6 {
    padding: -4.5rem !important; }
  .pt-sm--6,
  .py-sm--6 {
    padding-top: -4.5rem !important; }
  .pr-sm--6,
  .px-sm--6 {
    padding-right: -4.5rem !important; }
  .pb-sm--6,
  .py-sm--6 {
    padding-bottom: -4.5rem !important; }
  .pl-sm--6,
  .px-sm--6 {
    padding-left: -4.5rem !important; }
  .p-sm--7 {
    padding: -6rem !important; }
  .pt-sm--7,
  .py-sm--7 {
    padding-top: -6rem !important; }
  .pr-sm--7,
  .px-sm--7 {
    padding-right: -6rem !important; }
  .pb-sm--7,
  .py-sm--7 {
    padding-bottom: -6rem !important; }
  .pl-sm--7,
  .px-sm--7 {
    padding-left: -6rem !important; }
  .p-sm-6 {
    padding: 4.5rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important; }
  .p-sm-7 {
    padding: 6rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n-1 {
    margin: 0.25rem !important; }
  .mt-sm-n-1,
  .my-sm-n-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-n-1,
  .mx-sm-n-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-n-1,
  .my-sm-n-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-n-1,
  .mx-sm-n-1 {
    margin-left: 0.25rem !important; }
  .m-sm-n-2 {
    margin: 0.5rem !important; }
  .mt-sm-n-2,
  .my-sm-n-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-n-2,
  .mx-sm-n-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-n-2,
  .my-sm-n-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-n-2,
  .mx-sm-n-2 {
    margin-left: 0.5rem !important; }
  .m-sm-n-3 {
    margin: 1rem !important; }
  .mt-sm-n-3,
  .my-sm-n-3 {
    margin-top: 1rem !important; }
  .mr-sm-n-3,
  .mx-sm-n-3 {
    margin-right: 1rem !important; }
  .mb-sm-n-3,
  .my-sm-n-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-n-3,
  .mx-sm-n-3 {
    margin-left: 1rem !important; }
  .m-sm-n-4 {
    margin: 1.5rem !important; }
  .mt-sm-n-4,
  .my-sm-n-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-n-4,
  .mx-sm-n-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-n-4,
  .my-sm-n-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-n-4,
  .mx-sm-n-4 {
    margin-left: 1.5rem !important; }
  .m-sm-n-5 {
    margin: 3rem !important; }
  .mt-sm-n-5,
  .my-sm-n-5 {
    margin-top: 3rem !important; }
  .mr-sm-n-5,
  .mx-sm-n-5 {
    margin-right: 3rem !important; }
  .mb-sm-n-5,
  .my-sm-n-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-n-5,
  .mx-sm-n-5 {
    margin-left: 3rem !important; }
  .m-sm-n-6 {
    margin: 4.5rem !important; }
  .mt-sm-n-6,
  .my-sm-n-6 {
    margin-top: 4.5rem !important; }
  .mr-sm-n-6,
  .mx-sm-n-6 {
    margin-right: 4.5rem !important; }
  .mb-sm-n-6,
  .my-sm-n-6 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-n-6,
  .mx-sm-n-6 {
    margin-left: 4.5rem !important; }
  .m-sm-n-7 {
    margin: 6rem !important; }
  .mt-sm-n-7,
  .my-sm-n-7 {
    margin-top: 6rem !important; }
  .mr-sm-n-7,
  .mx-sm-n-7 {
    margin-right: 6rem !important; }
  .mb-sm-n-7,
  .my-sm-n-7 {
    margin-bottom: 6rem !important; }
  .ml-sm-n-7,
  .mx-sm-n-7 {
    margin-left: 6rem !important; }
  .m-sm-n6 {
    margin: -4.5rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4.5rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4.5rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4.5rem !important; }
  .m-sm-n7 {
    margin: -6rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -6rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -6rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -6rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -6rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md--1 {
    margin: -0.25rem !important; }
  .mt-md--1,
  .my-md--1 {
    margin-top: -0.25rem !important; }
  .mr-md--1,
  .mx-md--1 {
    margin-right: -0.25rem !important; }
  .mb-md--1,
  .my-md--1 {
    margin-bottom: -0.25rem !important; }
  .ml-md--1,
  .mx-md--1 {
    margin-left: -0.25rem !important; }
  .m-md--2 {
    margin: -0.5rem !important; }
  .mt-md--2,
  .my-md--2 {
    margin-top: -0.5rem !important; }
  .mr-md--2,
  .mx-md--2 {
    margin-right: -0.5rem !important; }
  .mb-md--2,
  .my-md--2 {
    margin-bottom: -0.5rem !important; }
  .ml-md--2,
  .mx-md--2 {
    margin-left: -0.5rem !important; }
  .m-md--3 {
    margin: -1rem !important; }
  .mt-md--3,
  .my-md--3 {
    margin-top: -1rem !important; }
  .mr-md--3,
  .mx-md--3 {
    margin-right: -1rem !important; }
  .mb-md--3,
  .my-md--3 {
    margin-bottom: -1rem !important; }
  .ml-md--3,
  .mx-md--3 {
    margin-left: -1rem !important; }
  .m-md--4 {
    margin: -1.5rem !important; }
  .mt-md--4,
  .my-md--4 {
    margin-top: -1.5rem !important; }
  .mr-md--4,
  .mx-md--4 {
    margin-right: -1.5rem !important; }
  .mb-md--4,
  .my-md--4 {
    margin-bottom: -1.5rem !important; }
  .ml-md--4,
  .mx-md--4 {
    margin-left: -1.5rem !important; }
  .m-md--5 {
    margin: -3rem !important; }
  .mt-md--5,
  .my-md--5 {
    margin-top: -3rem !important; }
  .mr-md--5,
  .mx-md--5 {
    margin-right: -3rem !important; }
  .mb-md--5,
  .my-md--5 {
    margin-bottom: -3rem !important; }
  .ml-md--5,
  .mx-md--5 {
    margin-left: -3rem !important; }
  .m-md--6 {
    margin: -4.5rem !important; }
  .mt-md--6,
  .my-md--6 {
    margin-top: -4.5rem !important; }
  .mr-md--6,
  .mx-md--6 {
    margin-right: -4.5rem !important; }
  .mb-md--6,
  .my-md--6 {
    margin-bottom: -4.5rem !important; }
  .ml-md--6,
  .mx-md--6 {
    margin-left: -4.5rem !important; }
  .m-md--7 {
    margin: -6rem !important; }
  .mt-md--7,
  .my-md--7 {
    margin-top: -6rem !important; }
  .mr-md--7,
  .mx-md--7 {
    margin-right: -6rem !important; }
  .mb-md--7,
  .my-md--7 {
    margin-bottom: -6rem !important; }
  .ml-md--7,
  .mx-md--7 {
    margin-left: -6rem !important; }
  .m-md-6 {
    margin: 4.5rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important; }
  .m-md-7 {
    margin: 6rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md--1 {
    padding: -0.25rem !important; }
  .pt-md--1,
  .py-md--1 {
    padding-top: -0.25rem !important; }
  .pr-md--1,
  .px-md--1 {
    padding-right: -0.25rem !important; }
  .pb-md--1,
  .py-md--1 {
    padding-bottom: -0.25rem !important; }
  .pl-md--1,
  .px-md--1 {
    padding-left: -0.25rem !important; }
  .p-md--2 {
    padding: -0.5rem !important; }
  .pt-md--2,
  .py-md--2 {
    padding-top: -0.5rem !important; }
  .pr-md--2,
  .px-md--2 {
    padding-right: -0.5rem !important; }
  .pb-md--2,
  .py-md--2 {
    padding-bottom: -0.5rem !important; }
  .pl-md--2,
  .px-md--2 {
    padding-left: -0.5rem !important; }
  .p-md--3 {
    padding: -1rem !important; }
  .pt-md--3,
  .py-md--3 {
    padding-top: -1rem !important; }
  .pr-md--3,
  .px-md--3 {
    padding-right: -1rem !important; }
  .pb-md--3,
  .py-md--3 {
    padding-bottom: -1rem !important; }
  .pl-md--3,
  .px-md--3 {
    padding-left: -1rem !important; }
  .p-md--4 {
    padding: -1.5rem !important; }
  .pt-md--4,
  .py-md--4 {
    padding-top: -1.5rem !important; }
  .pr-md--4,
  .px-md--4 {
    padding-right: -1.5rem !important; }
  .pb-md--4,
  .py-md--4 {
    padding-bottom: -1.5rem !important; }
  .pl-md--4,
  .px-md--4 {
    padding-left: -1.5rem !important; }
  .p-md--5 {
    padding: -3rem !important; }
  .pt-md--5,
  .py-md--5 {
    padding-top: -3rem !important; }
  .pr-md--5,
  .px-md--5 {
    padding-right: -3rem !important; }
  .pb-md--5,
  .py-md--5 {
    padding-bottom: -3rem !important; }
  .pl-md--5,
  .px-md--5 {
    padding-left: -3rem !important; }
  .p-md--6 {
    padding: -4.5rem !important; }
  .pt-md--6,
  .py-md--6 {
    padding-top: -4.5rem !important; }
  .pr-md--6,
  .px-md--6 {
    padding-right: -4.5rem !important; }
  .pb-md--6,
  .py-md--6 {
    padding-bottom: -4.5rem !important; }
  .pl-md--6,
  .px-md--6 {
    padding-left: -4.5rem !important; }
  .p-md--7 {
    padding: -6rem !important; }
  .pt-md--7,
  .py-md--7 {
    padding-top: -6rem !important; }
  .pr-md--7,
  .px-md--7 {
    padding-right: -6rem !important; }
  .pb-md--7,
  .py-md--7 {
    padding-bottom: -6rem !important; }
  .pl-md--7,
  .px-md--7 {
    padding-left: -6rem !important; }
  .p-md-6 {
    padding: 4.5rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important; }
  .p-md-7 {
    padding: 6rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n-1 {
    margin: 0.25rem !important; }
  .mt-md-n-1,
  .my-md-n-1 {
    margin-top: 0.25rem !important; }
  .mr-md-n-1,
  .mx-md-n-1 {
    margin-right: 0.25rem !important; }
  .mb-md-n-1,
  .my-md-n-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-n-1,
  .mx-md-n-1 {
    margin-left: 0.25rem !important; }
  .m-md-n-2 {
    margin: 0.5rem !important; }
  .mt-md-n-2,
  .my-md-n-2 {
    margin-top: 0.5rem !important; }
  .mr-md-n-2,
  .mx-md-n-2 {
    margin-right: 0.5rem !important; }
  .mb-md-n-2,
  .my-md-n-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-n-2,
  .mx-md-n-2 {
    margin-left: 0.5rem !important; }
  .m-md-n-3 {
    margin: 1rem !important; }
  .mt-md-n-3,
  .my-md-n-3 {
    margin-top: 1rem !important; }
  .mr-md-n-3,
  .mx-md-n-3 {
    margin-right: 1rem !important; }
  .mb-md-n-3,
  .my-md-n-3 {
    margin-bottom: 1rem !important; }
  .ml-md-n-3,
  .mx-md-n-3 {
    margin-left: 1rem !important; }
  .m-md-n-4 {
    margin: 1.5rem !important; }
  .mt-md-n-4,
  .my-md-n-4 {
    margin-top: 1.5rem !important; }
  .mr-md-n-4,
  .mx-md-n-4 {
    margin-right: 1.5rem !important; }
  .mb-md-n-4,
  .my-md-n-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-n-4,
  .mx-md-n-4 {
    margin-left: 1.5rem !important; }
  .m-md-n-5 {
    margin: 3rem !important; }
  .mt-md-n-5,
  .my-md-n-5 {
    margin-top: 3rem !important; }
  .mr-md-n-5,
  .mx-md-n-5 {
    margin-right: 3rem !important; }
  .mb-md-n-5,
  .my-md-n-5 {
    margin-bottom: 3rem !important; }
  .ml-md-n-5,
  .mx-md-n-5 {
    margin-left: 3rem !important; }
  .m-md-n-6 {
    margin: 4.5rem !important; }
  .mt-md-n-6,
  .my-md-n-6 {
    margin-top: 4.5rem !important; }
  .mr-md-n-6,
  .mx-md-n-6 {
    margin-right: 4.5rem !important; }
  .mb-md-n-6,
  .my-md-n-6 {
    margin-bottom: 4.5rem !important; }
  .ml-md-n-6,
  .mx-md-n-6 {
    margin-left: 4.5rem !important; }
  .m-md-n-7 {
    margin: 6rem !important; }
  .mt-md-n-7,
  .my-md-n-7 {
    margin-top: 6rem !important; }
  .mr-md-n-7,
  .mx-md-n-7 {
    margin-right: 6rem !important; }
  .mb-md-n-7,
  .my-md-n-7 {
    margin-bottom: 6rem !important; }
  .ml-md-n-7,
  .mx-md-n-7 {
    margin-left: 6rem !important; }
  .m-md-n6 {
    margin: -4.5rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4.5rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4.5rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4.5rem !important; }
  .m-md-n7 {
    margin: -6rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -6rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -6rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -6rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -6rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg--1 {
    margin: -0.25rem !important; }
  .mt-lg--1,
  .my-lg--1 {
    margin-top: -0.25rem !important; }
  .mr-lg--1,
  .mx-lg--1 {
    margin-right: -0.25rem !important; }
  .mb-lg--1,
  .my-lg--1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg--1,
  .mx-lg--1 {
    margin-left: -0.25rem !important; }
  .m-lg--2 {
    margin: -0.5rem !important; }
  .mt-lg--2,
  .my-lg--2 {
    margin-top: -0.5rem !important; }
  .mr-lg--2,
  .mx-lg--2 {
    margin-right: -0.5rem !important; }
  .mb-lg--2,
  .my-lg--2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg--2,
  .mx-lg--2 {
    margin-left: -0.5rem !important; }
  .m-lg--3 {
    margin: -1rem !important; }
  .mt-lg--3,
  .my-lg--3 {
    margin-top: -1rem !important; }
  .mr-lg--3,
  .mx-lg--3 {
    margin-right: -1rem !important; }
  .mb-lg--3,
  .my-lg--3 {
    margin-bottom: -1rem !important; }
  .ml-lg--3,
  .mx-lg--3 {
    margin-left: -1rem !important; }
  .m-lg--4 {
    margin: -1.5rem !important; }
  .mt-lg--4,
  .my-lg--4 {
    margin-top: -1.5rem !important; }
  .mr-lg--4,
  .mx-lg--4 {
    margin-right: -1.5rem !important; }
  .mb-lg--4,
  .my-lg--4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg--4,
  .mx-lg--4 {
    margin-left: -1.5rem !important; }
  .m-lg--5 {
    margin: -3rem !important; }
  .mt-lg--5,
  .my-lg--5 {
    margin-top: -3rem !important; }
  .mr-lg--5,
  .mx-lg--5 {
    margin-right: -3rem !important; }
  .mb-lg--5,
  .my-lg--5 {
    margin-bottom: -3rem !important; }
  .ml-lg--5,
  .mx-lg--5 {
    margin-left: -3rem !important; }
  .m-lg--6 {
    margin: -4.5rem !important; }
  .mt-lg--6,
  .my-lg--6 {
    margin-top: -4.5rem !important; }
  .mr-lg--6,
  .mx-lg--6 {
    margin-right: -4.5rem !important; }
  .mb-lg--6,
  .my-lg--6 {
    margin-bottom: -4.5rem !important; }
  .ml-lg--6,
  .mx-lg--6 {
    margin-left: -4.5rem !important; }
  .m-lg--7 {
    margin: -6rem !important; }
  .mt-lg--7,
  .my-lg--7 {
    margin-top: -6rem !important; }
  .mr-lg--7,
  .mx-lg--7 {
    margin-right: -6rem !important; }
  .mb-lg--7,
  .my-lg--7 {
    margin-bottom: -6rem !important; }
  .ml-lg--7,
  .mx-lg--7 {
    margin-left: -6rem !important; }
  .m-lg-6 {
    margin: 4.5rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important; }
  .m-lg-7 {
    margin: 6rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg--1 {
    padding: -0.25rem !important; }
  .pt-lg--1,
  .py-lg--1 {
    padding-top: -0.25rem !important; }
  .pr-lg--1,
  .px-lg--1 {
    padding-right: -0.25rem !important; }
  .pb-lg--1,
  .py-lg--1 {
    padding-bottom: -0.25rem !important; }
  .pl-lg--1,
  .px-lg--1 {
    padding-left: -0.25rem !important; }
  .p-lg--2 {
    padding: -0.5rem !important; }
  .pt-lg--2,
  .py-lg--2 {
    padding-top: -0.5rem !important; }
  .pr-lg--2,
  .px-lg--2 {
    padding-right: -0.5rem !important; }
  .pb-lg--2,
  .py-lg--2 {
    padding-bottom: -0.5rem !important; }
  .pl-lg--2,
  .px-lg--2 {
    padding-left: -0.5rem !important; }
  .p-lg--3 {
    padding: -1rem !important; }
  .pt-lg--3,
  .py-lg--3 {
    padding-top: -1rem !important; }
  .pr-lg--3,
  .px-lg--3 {
    padding-right: -1rem !important; }
  .pb-lg--3,
  .py-lg--3 {
    padding-bottom: -1rem !important; }
  .pl-lg--3,
  .px-lg--3 {
    padding-left: -1rem !important; }
  .p-lg--4 {
    padding: -1.5rem !important; }
  .pt-lg--4,
  .py-lg--4 {
    padding-top: -1.5rem !important; }
  .pr-lg--4,
  .px-lg--4 {
    padding-right: -1.5rem !important; }
  .pb-lg--4,
  .py-lg--4 {
    padding-bottom: -1.5rem !important; }
  .pl-lg--4,
  .px-lg--4 {
    padding-left: -1.5rem !important; }
  .p-lg--5 {
    padding: -3rem !important; }
  .pt-lg--5,
  .py-lg--5 {
    padding-top: -3rem !important; }
  .pr-lg--5,
  .px-lg--5 {
    padding-right: -3rem !important; }
  .pb-lg--5,
  .py-lg--5 {
    padding-bottom: -3rem !important; }
  .pl-lg--5,
  .px-lg--5 {
    padding-left: -3rem !important; }
  .p-lg--6 {
    padding: -4.5rem !important; }
  .pt-lg--6,
  .py-lg--6 {
    padding-top: -4.5rem !important; }
  .pr-lg--6,
  .px-lg--6 {
    padding-right: -4.5rem !important; }
  .pb-lg--6,
  .py-lg--6 {
    padding-bottom: -4.5rem !important; }
  .pl-lg--6,
  .px-lg--6 {
    padding-left: -4.5rem !important; }
  .p-lg--7 {
    padding: -6rem !important; }
  .pt-lg--7,
  .py-lg--7 {
    padding-top: -6rem !important; }
  .pr-lg--7,
  .px-lg--7 {
    padding-right: -6rem !important; }
  .pb-lg--7,
  .py-lg--7 {
    padding-bottom: -6rem !important; }
  .pl-lg--7,
  .px-lg--7 {
    padding-left: -6rem !important; }
  .p-lg-6 {
    padding: 4.5rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important; }
  .p-lg-7 {
    padding: 6rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n-1 {
    margin: 0.25rem !important; }
  .mt-lg-n-1,
  .my-lg-n-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-n-1,
  .mx-lg-n-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-n-1,
  .my-lg-n-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-n-1,
  .mx-lg-n-1 {
    margin-left: 0.25rem !important; }
  .m-lg-n-2 {
    margin: 0.5rem !important; }
  .mt-lg-n-2,
  .my-lg-n-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-n-2,
  .mx-lg-n-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-n-2,
  .my-lg-n-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-n-2,
  .mx-lg-n-2 {
    margin-left: 0.5rem !important; }
  .m-lg-n-3 {
    margin: 1rem !important; }
  .mt-lg-n-3,
  .my-lg-n-3 {
    margin-top: 1rem !important; }
  .mr-lg-n-3,
  .mx-lg-n-3 {
    margin-right: 1rem !important; }
  .mb-lg-n-3,
  .my-lg-n-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-n-3,
  .mx-lg-n-3 {
    margin-left: 1rem !important; }
  .m-lg-n-4 {
    margin: 1.5rem !important; }
  .mt-lg-n-4,
  .my-lg-n-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-n-4,
  .mx-lg-n-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-n-4,
  .my-lg-n-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-n-4,
  .mx-lg-n-4 {
    margin-left: 1.5rem !important; }
  .m-lg-n-5 {
    margin: 3rem !important; }
  .mt-lg-n-5,
  .my-lg-n-5 {
    margin-top: 3rem !important; }
  .mr-lg-n-5,
  .mx-lg-n-5 {
    margin-right: 3rem !important; }
  .mb-lg-n-5,
  .my-lg-n-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-n-5,
  .mx-lg-n-5 {
    margin-left: 3rem !important; }
  .m-lg-n-6 {
    margin: 4.5rem !important; }
  .mt-lg-n-6,
  .my-lg-n-6 {
    margin-top: 4.5rem !important; }
  .mr-lg-n-6,
  .mx-lg-n-6 {
    margin-right: 4.5rem !important; }
  .mb-lg-n-6,
  .my-lg-n-6 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-n-6,
  .mx-lg-n-6 {
    margin-left: 4.5rem !important; }
  .m-lg-n-7 {
    margin: 6rem !important; }
  .mt-lg-n-7,
  .my-lg-n-7 {
    margin-top: 6rem !important; }
  .mr-lg-n-7,
  .mx-lg-n-7 {
    margin-right: 6rem !important; }
  .mb-lg-n-7,
  .my-lg-n-7 {
    margin-bottom: 6rem !important; }
  .ml-lg-n-7,
  .mx-lg-n-7 {
    margin-left: 6rem !important; }
  .m-lg-n6 {
    margin: -4.5rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4.5rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4.5rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4.5rem !important; }
  .m-lg-n7 {
    margin: -6rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -6rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -6rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -6rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -6rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl--1 {
    margin: -0.25rem !important; }
  .mt-xl--1,
  .my-xl--1 {
    margin-top: -0.25rem !important; }
  .mr-xl--1,
  .mx-xl--1 {
    margin-right: -0.25rem !important; }
  .mb-xl--1,
  .my-xl--1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl--1,
  .mx-xl--1 {
    margin-left: -0.25rem !important; }
  .m-xl--2 {
    margin: -0.5rem !important; }
  .mt-xl--2,
  .my-xl--2 {
    margin-top: -0.5rem !important; }
  .mr-xl--2,
  .mx-xl--2 {
    margin-right: -0.5rem !important; }
  .mb-xl--2,
  .my-xl--2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl--2,
  .mx-xl--2 {
    margin-left: -0.5rem !important; }
  .m-xl--3 {
    margin: -1rem !important; }
  .mt-xl--3,
  .my-xl--3 {
    margin-top: -1rem !important; }
  .mr-xl--3,
  .mx-xl--3 {
    margin-right: -1rem !important; }
  .mb-xl--3,
  .my-xl--3 {
    margin-bottom: -1rem !important; }
  .ml-xl--3,
  .mx-xl--3 {
    margin-left: -1rem !important; }
  .m-xl--4 {
    margin: -1.5rem !important; }
  .mt-xl--4,
  .my-xl--4 {
    margin-top: -1.5rem !important; }
  .mr-xl--4,
  .mx-xl--4 {
    margin-right: -1.5rem !important; }
  .mb-xl--4,
  .my-xl--4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl--4,
  .mx-xl--4 {
    margin-left: -1.5rem !important; }
  .m-xl--5 {
    margin: -3rem !important; }
  .mt-xl--5,
  .my-xl--5 {
    margin-top: -3rem !important; }
  .mr-xl--5,
  .mx-xl--5 {
    margin-right: -3rem !important; }
  .mb-xl--5,
  .my-xl--5 {
    margin-bottom: -3rem !important; }
  .ml-xl--5,
  .mx-xl--5 {
    margin-left: -3rem !important; }
  .m-xl--6 {
    margin: -4.5rem !important; }
  .mt-xl--6,
  .my-xl--6 {
    margin-top: -4.5rem !important; }
  .mr-xl--6,
  .mx-xl--6 {
    margin-right: -4.5rem !important; }
  .mb-xl--6,
  .my-xl--6 {
    margin-bottom: -4.5rem !important; }
  .ml-xl--6,
  .mx-xl--6 {
    margin-left: -4.5rem !important; }
  .m-xl--7 {
    margin: -6rem !important; }
  .mt-xl--7,
  .my-xl--7 {
    margin-top: -6rem !important; }
  .mr-xl--7,
  .mx-xl--7 {
    margin-right: -6rem !important; }
  .mb-xl--7,
  .my-xl--7 {
    margin-bottom: -6rem !important; }
  .ml-xl--7,
  .mx-xl--7 {
    margin-left: -6rem !important; }
  .m-xl-6 {
    margin: 4.5rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important; }
  .m-xl-7 {
    margin: 6rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl--1 {
    padding: -0.25rem !important; }
  .pt-xl--1,
  .py-xl--1 {
    padding-top: -0.25rem !important; }
  .pr-xl--1,
  .px-xl--1 {
    padding-right: -0.25rem !important; }
  .pb-xl--1,
  .py-xl--1 {
    padding-bottom: -0.25rem !important; }
  .pl-xl--1,
  .px-xl--1 {
    padding-left: -0.25rem !important; }
  .p-xl--2 {
    padding: -0.5rem !important; }
  .pt-xl--2,
  .py-xl--2 {
    padding-top: -0.5rem !important; }
  .pr-xl--2,
  .px-xl--2 {
    padding-right: -0.5rem !important; }
  .pb-xl--2,
  .py-xl--2 {
    padding-bottom: -0.5rem !important; }
  .pl-xl--2,
  .px-xl--2 {
    padding-left: -0.5rem !important; }
  .p-xl--3 {
    padding: -1rem !important; }
  .pt-xl--3,
  .py-xl--3 {
    padding-top: -1rem !important; }
  .pr-xl--3,
  .px-xl--3 {
    padding-right: -1rem !important; }
  .pb-xl--3,
  .py-xl--3 {
    padding-bottom: -1rem !important; }
  .pl-xl--3,
  .px-xl--3 {
    padding-left: -1rem !important; }
  .p-xl--4 {
    padding: -1.5rem !important; }
  .pt-xl--4,
  .py-xl--4 {
    padding-top: -1.5rem !important; }
  .pr-xl--4,
  .px-xl--4 {
    padding-right: -1.5rem !important; }
  .pb-xl--4,
  .py-xl--4 {
    padding-bottom: -1.5rem !important; }
  .pl-xl--4,
  .px-xl--4 {
    padding-left: -1.5rem !important; }
  .p-xl--5 {
    padding: -3rem !important; }
  .pt-xl--5,
  .py-xl--5 {
    padding-top: -3rem !important; }
  .pr-xl--5,
  .px-xl--5 {
    padding-right: -3rem !important; }
  .pb-xl--5,
  .py-xl--5 {
    padding-bottom: -3rem !important; }
  .pl-xl--5,
  .px-xl--5 {
    padding-left: -3rem !important; }
  .p-xl--6 {
    padding: -4.5rem !important; }
  .pt-xl--6,
  .py-xl--6 {
    padding-top: -4.5rem !important; }
  .pr-xl--6,
  .px-xl--6 {
    padding-right: -4.5rem !important; }
  .pb-xl--6,
  .py-xl--6 {
    padding-bottom: -4.5rem !important; }
  .pl-xl--6,
  .px-xl--6 {
    padding-left: -4.5rem !important; }
  .p-xl--7 {
    padding: -6rem !important; }
  .pt-xl--7,
  .py-xl--7 {
    padding-top: -6rem !important; }
  .pr-xl--7,
  .px-xl--7 {
    padding-right: -6rem !important; }
  .pb-xl--7,
  .py-xl--7 {
    padding-bottom: -6rem !important; }
  .pl-xl--7,
  .px-xl--7 {
    padding-left: -6rem !important; }
  .p-xl-6 {
    padding: 4.5rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important; }
  .p-xl-7 {
    padding: 6rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n-1 {
    margin: 0.25rem !important; }
  .mt-xl-n-1,
  .my-xl-n-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-n-1,
  .mx-xl-n-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-n-1,
  .my-xl-n-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-n-1,
  .mx-xl-n-1 {
    margin-left: 0.25rem !important; }
  .m-xl-n-2 {
    margin: 0.5rem !important; }
  .mt-xl-n-2,
  .my-xl-n-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-n-2,
  .mx-xl-n-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-n-2,
  .my-xl-n-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-n-2,
  .mx-xl-n-2 {
    margin-left: 0.5rem !important; }
  .m-xl-n-3 {
    margin: 1rem !important; }
  .mt-xl-n-3,
  .my-xl-n-3 {
    margin-top: 1rem !important; }
  .mr-xl-n-3,
  .mx-xl-n-3 {
    margin-right: 1rem !important; }
  .mb-xl-n-3,
  .my-xl-n-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-n-3,
  .mx-xl-n-3 {
    margin-left: 1rem !important; }
  .m-xl-n-4 {
    margin: 1.5rem !important; }
  .mt-xl-n-4,
  .my-xl-n-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-n-4,
  .mx-xl-n-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-n-4,
  .my-xl-n-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-n-4,
  .mx-xl-n-4 {
    margin-left: 1.5rem !important; }
  .m-xl-n-5 {
    margin: 3rem !important; }
  .mt-xl-n-5,
  .my-xl-n-5 {
    margin-top: 3rem !important; }
  .mr-xl-n-5,
  .mx-xl-n-5 {
    margin-right: 3rem !important; }
  .mb-xl-n-5,
  .my-xl-n-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-n-5,
  .mx-xl-n-5 {
    margin-left: 3rem !important; }
  .m-xl-n-6 {
    margin: 4.5rem !important; }
  .mt-xl-n-6,
  .my-xl-n-6 {
    margin-top: 4.5rem !important; }
  .mr-xl-n-6,
  .mx-xl-n-6 {
    margin-right: 4.5rem !important; }
  .mb-xl-n-6,
  .my-xl-n-6 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-n-6,
  .mx-xl-n-6 {
    margin-left: 4.5rem !important; }
  .m-xl-n-7 {
    margin: 6rem !important; }
  .mt-xl-n-7,
  .my-xl-n-7 {
    margin-top: 6rem !important; }
  .mr-xl-n-7,
  .mx-xl-n-7 {
    margin-right: 6rem !important; }
  .mb-xl-n-7,
  .my-xl-n-7 {
    margin-bottom: 6rem !important; }
  .ml-xl-n-7,
  .mx-xl-n-7 {
    margin-left: 6rem !important; }
  .m-xl-n6 {
    margin: -4.5rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4.5rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4.5rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4.5rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4.5rem !important; }
  .m-xl-n7 {
    margin: -6rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -6rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -6rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -6rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -6rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFFF !important; }

.text-primary {
  color: #005979 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #00212d !important; }

.text-secondary {
  color: #e44a50 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #c41e24 !important; }

.text-success {
  color: #5FAD56 !important; }

a.text-success:hover, a.text-success:focus {
  color: #427b3c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #BA1F33 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #781421 !important; }

.text-light {
  color: #FAFAFA !important; }

a.text-light:hover, a.text-light:focus {
  color: #d4d4d4 !important; }

.text-dark {
  color: #212529 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-alert {
  color: #BA1F33 !important; }

a.text-alert:hover, a.text-alert:focus {
  color: #781421 !important; }

.text-midnight-green {
  color: #005979 !important; }

a.text-midnight-green:hover, a.text-midnight-green:focus {
  color: #00212d !important; }

.text-rose {
  color: #F45B69 !important; }

a.text-rose:hover, a.text-rose:focus {
  color: #ef1327 !important; }

.text-green {
  color: #5FAD56 !important; }

a.text-green:hover, a.text-green:focus {
  color: #427b3c !important; }

.text-red {
  color: #BA1F33 !important; }

a.text-red:hover, a.text-red:focus {
  color: #781421 !important; }

.text-white {
  color: #FFFFFF !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-slate-grey {
  color: #212529 !important; }

a.text-slate-grey:hover, a.text-slate-grey:focus {
  color: black !important; }

.text-slate-grey-opaque {
  color: rgba(33, 37, 41, 0.1) !important; }

a.text-slate-grey-opaque:hover, a.text-slate-grey-opaque:focus {
  color: rgba(0, 0, 0, 0.1) !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 2px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 2px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFFFFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.btn-brand-primary {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #005979;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-primary:hover {
    color: #FFFFFF;
    background-color: #003d53;
    border-color: #003346;
    transform: scale(1.0125); }
    .btn-brand-primary:hover::after {
      border-color: #003346; }
  .btn-brand-primary:focus, .btn-brand-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 114, 141, 0.5); }
  .btn-brand-primary.disabled, .btn-brand-primary:disabled {
    color: #FFFFFF;
    background-color: #005979;
    border-color: #005979; }
  .btn-brand-primary:not(:disabled):not(.disabled):active, .btn-brand-primary:not(:disabled):not(.disabled).active,
  .show > .btn-brand-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #003346;
    border-color: #002a39; }
    .btn-brand-primary:not(:disabled):not(.disabled):active:focus, .btn-brand-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 114, 141, 0.5); }
  .btn-brand-primary::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #005979;
    background-color: #FFFFFF; }

.btn-brand-secondary {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #e44a50;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-secondary:hover {
    color: #FFFFFF;
    background-color: #df2930;
    border-color: #da2128;
    transform: scale(1.0125); }
    .btn-brand-secondary:hover::after {
      border-color: #da2128; }
  .btn-brand-secondary:focus, .btn-brand-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 101, 106, 0.5); }
  .btn-brand-secondary.disabled, .btn-brand-secondary:disabled {
    color: #FFFFFF;
    background-color: #e44a50;
    border-color: #e44a50; }
  .btn-brand-secondary:not(:disabled):not(.disabled):active, .btn-brand-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-brand-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #da2128;
    border-color: #cf1f26; }
    .btn-brand-secondary:not(:disabled):not(.disabled):active:focus, .btn-brand-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(232, 101, 106, 0.5); }
  .btn-brand-secondary::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #e44a50;
    background-color: #FFFFFF; }

.btn-brand-success {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #5FAD56;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-success:hover {
    color: #FFFFFF;
    background-color: #509548;
    border-color: #4b8c44;
    transform: scale(1.0125); }
    .btn-brand-success:hover::after {
      border-color: #4b8c44; }
  .btn-brand-success:focus, .btn-brand-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 185, 111, 0.5); }
  .btn-brand-success.disabled, .btn-brand-success:disabled {
    color: #FFFFFF;
    background-color: #5FAD56;
    border-color: #5FAD56; }
  .btn-brand-success:not(:disabled):not(.disabled):active, .btn-brand-success:not(:disabled):not(.disabled).active,
  .show > .btn-brand-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #4b8c44;
    border-color: #478340; }
    .btn-brand-success:not(:disabled):not(.disabled):active:focus, .btn-brand-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 185, 111, 0.5); }
  .btn-brand-success::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #5FAD56;
    background-color: #FFFFFF; }

.btn-brand-info {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #17a2b8;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-info:hover {
    color: #FFFFFF;
    background-color: #138496;
    border-color: #117a8b;
    transform: scale(1.0125); }
    .btn-brand-info:hover::after {
      border-color: #117a8b; }
  .btn-brand-info:focus, .btn-brand-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-brand-info.disabled, .btn-brand-info:disabled {
    color: #FFFFFF;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-brand-info:not(:disabled):not(.disabled):active, .btn-brand-info:not(:disabled):not(.disabled).active,
  .show > .btn-brand-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-brand-info:not(:disabled):not(.disabled):active:focus, .btn-brand-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-brand-info::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #17a2b8;
    background-color: #FFFFFF; }

.btn-brand-warning {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #212529;
  background-color: #ffc107;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    transform: scale(1.0125); }
    .btn-brand-warning:hover::after {
      border-color: #d39e00; }
  .btn-brand-warning:focus, .btn-brand-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-brand-warning.disabled, .btn-brand-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-brand-warning:not(:disabled):not(.disabled):active, .btn-brand-warning:not(:disabled):not(.disabled).active,
  .show > .btn-brand-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-brand-warning:not(:disabled):not(.disabled):active:focus, .btn-brand-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-brand-warning::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #ffc107;
    background-color: #FFFFFF; }

.btn-brand-danger {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #BA1F33;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-danger:hover {
    color: #FFFFFF;
    background-color: #991a2a;
    border-color: #8e1827;
    transform: scale(1.0125); }
    .btn-brand-danger:hover::after {
      border-color: #8e1827; }
  .btn-brand-danger:focus, .btn-brand-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }
  .btn-brand-danger.disabled, .btn-brand-danger:disabled {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
  .btn-brand-danger:not(:disabled):not(.disabled):active, .btn-brand-danger:not(:disabled):not(.disabled).active,
  .show > .btn-brand-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #8e1827;
    border-color: #831624; }
    .btn-brand-danger:not(:disabled):not(.disabled):active:focus, .btn-brand-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }
  .btn-brand-danger::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #BA1F33;
    background-color: #FFFFFF; }

.btn-brand-light {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #212529;
  background-color: #FAFAFA;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-light:hover {
    color: #212529;
    background-color: #e7e7e7;
    border-color: #e1e1e1;
    transform: scale(1.0125); }
    .btn-brand-light:hover::after {
      border-color: #e1e1e1; }
  .btn-brand-light:focus, .btn-brand-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5); }
  .btn-brand-light.disabled, .btn-brand-light:disabled {
    color: #212529;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
  .btn-brand-light:not(:disabled):not(.disabled):active, .btn-brand-light:not(:disabled):not(.disabled).active,
  .show > .btn-brand-light.dropdown-toggle {
    color: #212529;
    background-color: #e1e1e1;
    border-color: #dadada; }
    .btn-brand-light:not(:disabled):not(.disabled):active:focus, .btn-brand-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5); }
  .btn-brand-light::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #FAFAFA;
    background-color: #FFFFFF; }

.btn-brand-dark {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #212529;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-dark:hover {
    color: #FFFFFF;
    background-color: #101214;
    border-color: #0a0c0d;
    transform: scale(1.0125); }
    .btn-brand-dark:hover::after {
      border-color: #0a0c0d; }
  .btn-brand-dark:focus, .btn-brand-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-brand-dark.disabled, .btn-brand-dark:disabled {
    color: #FFFFFF;
    background-color: #212529;
    border-color: #212529; }
  .btn-brand-dark:not(:disabled):not(.disabled):active, .btn-brand-dark:not(:disabled):not(.disabled).active,
  .show > .btn-brand-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0a0c0d;
    border-color: #050506; }
    .btn-brand-dark:not(:disabled):not(.disabled):active:focus, .btn-brand-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-brand-dark::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #212529;
    background-color: #FFFFFF; }

.btn-brand-alert {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #BA1F33;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-alert:hover {
    color: #FFFFFF;
    background-color: #991a2a;
    border-color: #8e1827;
    transform: scale(1.0125); }
    .btn-brand-alert:hover::after {
      border-color: #8e1827; }
  .btn-brand-alert:focus, .btn-brand-alert.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }
  .btn-brand-alert.disabled, .btn-brand-alert:disabled {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
  .btn-brand-alert:not(:disabled):not(.disabled):active, .btn-brand-alert:not(:disabled):not(.disabled).active,
  .show > .btn-brand-alert.dropdown-toggle {
    color: #FFFFFF;
    background-color: #8e1827;
    border-color: #831624; }
    .btn-brand-alert:not(:disabled):not(.disabled):active:focus, .btn-brand-alert:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-alert.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }
  .btn-brand-alert::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #BA1F33;
    background-color: #FFFFFF; }

.btn-brand-midnight-green {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #005979;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-midnight-green:hover {
    color: #FFFFFF;
    background-color: #003d53;
    border-color: #003346;
    transform: scale(1.0125); }
    .btn-brand-midnight-green:hover::after {
      border-color: #003346; }
  .btn-brand-midnight-green:focus, .btn-brand-midnight-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 114, 141, 0.5); }
  .btn-brand-midnight-green.disabled, .btn-brand-midnight-green:disabled {
    color: #FFFFFF;
    background-color: #005979;
    border-color: #005979; }
  .btn-brand-midnight-green:not(:disabled):not(.disabled):active, .btn-brand-midnight-green:not(:disabled):not(.disabled).active,
  .show > .btn-brand-midnight-green.dropdown-toggle {
    color: #FFFFFF;
    background-color: #003346;
    border-color: #002a39; }
    .btn-brand-midnight-green:not(:disabled):not(.disabled):active:focus, .btn-brand-midnight-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-midnight-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 114, 141, 0.5); }
  .btn-brand-midnight-green::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #005979;
    background-color: #FFFFFF; }

.btn-brand-rose {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #F45B69;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-rose:hover {
    color: #FFFFFF;
    background-color: #f23748;
    border-color: #f12b3d;
    transform: scale(1.0125); }
    .btn-brand-rose:hover::after {
      border-color: #f12b3d; }
  .btn-brand-rose:focus, .btn-brand-rose.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 116, 128, 0.5); }
  .btn-brand-rose.disabled, .btn-brand-rose:disabled {
    color: #FFFFFF;
    background-color: #F45B69;
    border-color: #F45B69; }
  .btn-brand-rose:not(:disabled):not(.disabled):active, .btn-brand-rose:not(:disabled):not(.disabled).active,
  .show > .btn-brand-rose.dropdown-toggle {
    color: #FFFFFF;
    background-color: #f12b3d;
    border-color: #f01f32; }
    .btn-brand-rose:not(:disabled):not(.disabled):active:focus, .btn-brand-rose:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-rose.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 116, 128, 0.5); }
  .btn-brand-rose::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #F45B69;
    background-color: #FFFFFF; }

.btn-brand-green {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #5FAD56;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-green:hover {
    color: #FFFFFF;
    background-color: #509548;
    border-color: #4b8c44;
    transform: scale(1.0125); }
    .btn-brand-green:hover::after {
      border-color: #4b8c44; }
  .btn-brand-green:focus, .btn-brand-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 185, 111, 0.5); }
  .btn-brand-green.disabled, .btn-brand-green:disabled {
    color: #FFFFFF;
    background-color: #5FAD56;
    border-color: #5FAD56; }
  .btn-brand-green:not(:disabled):not(.disabled):active, .btn-brand-green:not(:disabled):not(.disabled).active,
  .show > .btn-brand-green.dropdown-toggle {
    color: #FFFFFF;
    background-color: #4b8c44;
    border-color: #478340; }
    .btn-brand-green:not(:disabled):not(.disabled):active:focus, .btn-brand-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 185, 111, 0.5); }
  .btn-brand-green::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #5FAD56;
    background-color: #FFFFFF; }

.btn-brand-red {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #BA1F33;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-red:hover {
    color: #FFFFFF;
    background-color: #991a2a;
    border-color: #8e1827;
    transform: scale(1.0125); }
    .btn-brand-red:hover::after {
      border-color: #8e1827; }
  .btn-brand-red:focus, .btn-brand-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }
  .btn-brand-red.disabled, .btn-brand-red:disabled {
    color: #FFFFFF;
    background-color: #BA1F33;
    border-color: #BA1F33; }
  .btn-brand-red:not(:disabled):not(.disabled):active, .btn-brand-red:not(:disabled):not(.disabled).active,
  .show > .btn-brand-red.dropdown-toggle {
    color: #FFFFFF;
    background-color: #8e1827;
    border-color: #831624; }
    .btn-brand-red:not(:disabled):not(.disabled):active:focus, .btn-brand-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(196, 65, 82, 0.5); }
  .btn-brand-red::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #BA1F33;
    background-color: #FFFFFF; }

.btn-brand-white {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #212529;
  background-color: #FFFFFF;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    transform: scale(1.0125); }
    .btn-brand-white:hover::after {
      border-color: #e6e6e6; }
  .btn-brand-white:focus, .btn-brand-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-brand-white.disabled, .btn-brand-white:disabled {
    color: #212529;
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-brand-white:not(:disabled):not(.disabled):active, .btn-brand-white:not(:disabled):not(.disabled).active,
  .show > .btn-brand-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-brand-white:not(:disabled):not(.disabled):active:focus, .btn-brand-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-brand-white::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #FFFFFF;
    background-color: #FFFFFF; }

.btn-brand-slate-grey {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: #212529;
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-slate-grey:hover {
    color: #FFFFFF;
    background-color: #101214;
    border-color: #0a0c0d;
    transform: scale(1.0125); }
    .btn-brand-slate-grey:hover::after {
      border-color: #0a0c0d; }
  .btn-brand-slate-grey:focus, .btn-brand-slate-grey.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-brand-slate-grey.disabled, .btn-brand-slate-grey:disabled {
    color: #FFFFFF;
    background-color: #212529;
    border-color: #212529; }
  .btn-brand-slate-grey:not(:disabled):not(.disabled):active, .btn-brand-slate-grey:not(:disabled):not(.disabled).active,
  .show > .btn-brand-slate-grey.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0a0c0d;
    border-color: #050506; }
    .btn-brand-slate-grey:not(:disabled):not(.disabled):active:focus, .btn-brand-slate-grey:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-slate-grey.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-brand-slate-grey::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid #212529;
    background-color: #FFFFFF; }

.btn-brand-slate-grey-opaque {
  position: relative;
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  background-color: rgba(33, 37, 41, 0.1);
  padding-right: calc(44px + 1rem / 2);
  border: 0;
  transition: 0.3s; }
  .btn-brand-slate-grey-opaque:hover {
    color: #FFFFFF;
    background-color: rgba(16, 18, 20, 0.1);
    border-color: rgba(10, 12, 13, 0.1);
    transform: scale(1.0125); }
    .btn-brand-slate-grey-opaque:hover::after {
      border-color: rgba(10, 12, 13, 0.1); }
  .btn-brand-slate-grey-opaque:focus, .btn-brand-slate-grey-opaque.focus {
    box-shadow: 0 0 0 0.2rem rgba(204, 205, 206, 0.5); }
  .btn-brand-slate-grey-opaque.disabled, .btn-brand-slate-grey-opaque:disabled {
    color: #FFFFFF;
    background-color: rgba(33, 37, 41, 0.1);
    border-color: rgba(33, 37, 41, 0.1); }
  .btn-brand-slate-grey-opaque:not(:disabled):not(.disabled):active, .btn-brand-slate-grey-opaque:not(:disabled):not(.disabled).active,
  .show > .btn-brand-slate-grey-opaque.dropdown-toggle {
    color: #FFFFFF;
    background-color: rgba(10, 12, 13, 0.1);
    border-color: rgba(5, 5, 6, 0.1); }
    .btn-brand-slate-grey-opaque:not(:disabled):not(.disabled):active:focus, .btn-brand-slate-grey-opaque:not(:disabled):not(.disabled).active:focus,
    .show > .btn-brand-slate-grey-opaque.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(204, 205, 206, 0.5); }
  .btn-brand-slate-grey-opaque::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 2px solid rgba(33, 37, 41, 0.1);
    background-color: #FFFFFF; }

/* Google Material Design - Component shadow guidelines

Default elevation values (dp)

Dialog - 24dp
Modal bottom sheet, Modal side sheet - 16dp
Navigation drawer - 16dp
Floating action button (FAB - pressed) - 12dp
Standard bottom sheet, Standard side sheet - 8dp
Bottom navigation bar - 8dp
Bottom app bar - 8dp
Menus and sub menus - 8dp
Card (when picked up) - 8dp
Contained button (pressed state) - 8dp
Floating action button (FAB - resting elevation), Snackbar - 6dp
Top app bar (scrolled state) - 4dp
Top app bar (resting elevation) - 0dp or 4dp
Refresh indicator, Search bar (scrolled state) - 3dp
Contained button (resting elevation) - 2dp
Search bar (resting elevation) - 1dp
Card (resting elevation) - 1dp
Switch - 1dp
Text button - 0dp
Standard side sheet - 0dp

*/
@font-face {
  font-family: 'Bebas Neue';
  font-weight: 200;
  src: url("../fonts/BebasNeue-Thin.otf") format("opentype"); }

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 300;
  src: url("../fonts/BebasNeue-Light.otf") format("opentype"); }

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 400;
  src: url("../fonts/BebasNeue-Regular.otf") format("opentype"); }

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 700;
  src: url("../fonts/BebasNeue-Bold.otf") format("opentype"); }

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  body .body-overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1030;
    transition: background-color 0.3s; }
  body main {
    margin-top: 75px;
    flex: 1 0 auto;
    overflow: hidden;
    transition: 0.3s; }
    body main.blur {
      filter: blur(4px) grayscale(50%); }
    @media screen and (max-width: 991.98px) {
      body main {
        margin-top: 56px; } }

.form-hidden {
  height: 0;
  opacity: 0;
  position: absolute; }

.bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .bg-img--fixed {
    background-attachment: fixed; }

.bg-img-contain {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat; }

.bg-transparent {
  background-color: transparent !important; }

.img-cover {
  object-fit: cover !important; }

.img-contain {
  object-fit: contain !important; }

.content-block {
  padding-top: 3rem;
  padding-bottom: 3rem; }
  @media screen and (max-width: 767.98px) {
    .content-block {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; } }
  .content-block--lg {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem; }
    @media screen and (max-width: 767.98px) {
      .content-block--lg {
        padding-top: 3rem;
        padding-bottom: 3rem; } }
  .content-block--xl {
    padding-top: 6rem;
    padding-bottom: 6rem; }
    @media screen and (max-width: 767.98px) {
      .content-block--xl {
        padding-top: 3rem;
        padding-bottom: 3rem; } }

.anchor {
  display: block;
  visibility: hidden; }
  @media screen and (max-width: 991.98px) {
    .anchor {
      height: 56px;
      margin-top: calc(56px * -1.25); } }
  @media screen and (min-width: 768px) {
    .anchor {
      height: 95px;
      margin-top: -95px; } }

.cursor-pointer {
  cursor: pointer !important; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.display-1, .display-2, .display-3, .display-4 {
  font-family: "Bebas Neue" -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold; }
  @media screen and (max-width: 767.98px) {
    .display-1, .display-2, .display-3, .display-4 {
      font-size: 2.625rem; } }

.font-family-sans-serif {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.font-family-display {
  font-family: "Bebas Neue" -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.font-weight-semi-bold {
  font-weight: 600 !important; }

.p-small {
  font-size: 0.875rem; }

.label {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #212529;
  letter-spacing: 1.6px;
  line-height: 22px;
  text-transform: uppercase; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-20 {
  width: 20% !important; }

.w-40 {
  width: 40% !important; }

.w-60 {
  width: 60% !important; }

.w-80 {
  width: 80% !important; }

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; }
  .w-sm-20 {
    width: 20% !important; }
  .w-sm-40 {
    width: 40% !important; }
  .w-sm-60 {
    width: 60% !important; }
  .w-sm-80 {
    width: 80% !important; } }

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; }
  .w-md-20 {
    width: 20% !important; }
  .w-md-40 {
    width: 40% !important; }
  .w-md-60 {
    width: 60% !important; }
  .w-md-80 {
    width: 80% !important; } }

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; }
  .w-lg-20 {
    width: 20% !important; }
  .w-lg-40 {
    width: 40% !important; }
  .w-lg-60 {
    width: 60% !important; }
  .w-lg-80 {
    width: 80% !important; } }

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; }
  .w-xl-20 {
    width: 20% !important; }
  .w-xl-40 {
    width: 40% !important; }
  .w-xl-60 {
    width: 60% !important; }
  .w-xl-80 {
    width: 80% !important; } }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-20 {
  height: 20% !important; }

.h-40 {
  height: 40% !important; }

.h-60 {
  height: 60% !important; }

.h-80 {
  height: 80% !important; }

@media (min-width: 576px) {
  .h-sm-25 {
    height: 25% !important; }
  .h-sm-50 {
    height: 50% !important; }
  .h-sm-75 {
    height: 75% !important; }
  .h-sm-100 {
    height: 100% !important; }
  .h-sm-auto {
    height: auto !important; }
  .h-sm-20 {
    height: 20% !important; }
  .h-sm-40 {
    height: 40% !important; }
  .h-sm-60 {
    height: 60% !important; }
  .h-sm-80 {
    height: 80% !important; } }

@media (min-width: 768px) {
  .h-md-25 {
    height: 25% !important; }
  .h-md-50 {
    height: 50% !important; }
  .h-md-75 {
    height: 75% !important; }
  .h-md-100 {
    height: 100% !important; }
  .h-md-auto {
    height: auto !important; }
  .h-md-20 {
    height: 20% !important; }
  .h-md-40 {
    height: 40% !important; }
  .h-md-60 {
    height: 60% !important; }
  .h-md-80 {
    height: 80% !important; } }

@media (min-width: 992px) {
  .h-lg-25 {
    height: 25% !important; }
  .h-lg-50 {
    height: 50% !important; }
  .h-lg-75 {
    height: 75% !important; }
  .h-lg-100 {
    height: 100% !important; }
  .h-lg-auto {
    height: auto !important; }
  .h-lg-20 {
    height: 20% !important; }
  .h-lg-40 {
    height: 40% !important; }
  .h-lg-60 {
    height: 60% !important; }
  .h-lg-80 {
    height: 80% !important; } }

@media (min-width: 1200px) {
  .h-xl-25 {
    height: 25% !important; }
  .h-xl-50 {
    height: 50% !important; }
  .h-xl-75 {
    height: 75% !important; }
  .h-xl-100 {
    height: 100% !important; }
  .h-xl-auto {
    height: auto !important; }
  .h-xl-20 {
    height: 20% !important; }
  .h-xl-40 {
    height: 40% !important; }
  .h-xl-60 {
    height: 60% !important; }
  .h-xl-80 {
    height: 80% !important; } }

.mw-25 {
  max-width: 25% !important; }

.mw-50 {
  max-width: 50% !important; }

.mw-75 {
  max-width: 75% !important; }

.mw-100 {
  max-width: 100% !important; }

.mw-auto {
  max-width: auto !important; }

.mw-20 {
  max-width: 20% !important; }

.mw-40 {
  max-width: 40% !important; }

.mw-60 {
  max-width: 60% !important; }

.mw-80 {
  max-width: 80% !important; }

@media (min-width: 576px) {
  .mw-sm-25 {
    max-width: 25% !important; }
  .mw-sm-50 {
    max-width: 50% !important; }
  .mw-sm-75 {
    max-width: 75% !important; }
  .mw-sm-100 {
    max-width: 100% !important; }
  .mw-sm-auto {
    max-width: auto !important; }
  .mw-sm-20 {
    max-width: 20% !important; }
  .mw-sm-40 {
    max-width: 40% !important; }
  .mw-sm-60 {
    max-width: 60% !important; }
  .mw-sm-80 {
    max-width: 80% !important; } }

@media (min-width: 768px) {
  .mw-md-25 {
    max-width: 25% !important; }
  .mw-md-50 {
    max-width: 50% !important; }
  .mw-md-75 {
    max-width: 75% !important; }
  .mw-md-100 {
    max-width: 100% !important; }
  .mw-md-auto {
    max-width: auto !important; }
  .mw-md-20 {
    max-width: 20% !important; }
  .mw-md-40 {
    max-width: 40% !important; }
  .mw-md-60 {
    max-width: 60% !important; }
  .mw-md-80 {
    max-width: 80% !important; } }

@media (min-width: 992px) {
  .mw-lg-25 {
    max-width: 25% !important; }
  .mw-lg-50 {
    max-width: 50% !important; }
  .mw-lg-75 {
    max-width: 75% !important; }
  .mw-lg-100 {
    max-width: 100% !important; }
  .mw-lg-auto {
    max-width: auto !important; }
  .mw-lg-20 {
    max-width: 20% !important; }
  .mw-lg-40 {
    max-width: 40% !important; }
  .mw-lg-60 {
    max-width: 60% !important; }
  .mw-lg-80 {
    max-width: 80% !important; } }

@media (min-width: 1200px) {
  .mw-xl-25 {
    max-width: 25% !important; }
  .mw-xl-50 {
    max-width: 50% !important; }
  .mw-xl-75 {
    max-width: 75% !important; }
  .mw-xl-100 {
    max-width: 100% !important; }
  .mw-xl-auto {
    max-width: auto !important; }
  .mw-xl-20 {
    max-width: 20% !important; }
  .mw-xl-40 {
    max-width: 40% !important; }
  .mw-xl-60 {
    max-width: 60% !important; }
  .mw-xl-80 {
    max-width: 80% !important; } }

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }
  .avatar--sm {
    width: 80px;
    height: 80px; }
  .avatar img {
    border: 3px solid #FFFFFF;
    border-radius: 50%; }

.badge {
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px; }

.btn {
  transition: 0.3s; }
  .btn.btn-shadow {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }
    .btn.btn-shadow:hover {
      box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
  .btn.btn-fmt {
    padding: 0.5rem 1.5rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 10px;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700; }
    .btn.btn-fmt:hover {
      border-top-left-radius: 0px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 0px; }
    @media screen and (min-width: 992px) {
      .btn.btn-fmt {
        min-width: 200px; } }
    @media screen and (max-width: 575.98px) {
      .btn.btn-fmt {
        display: block; } }

#confirm-payment {
  position: relative;
  min-height: 39px; }

.callback-block {
  position: relative;
  height: 500px;
  box-shadow: inset 0 10px 8px -8px #212529, inset 0 -10px 8px -8px #212529; }
  .callback-block::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 0; }
  .callback-block .block-content {
    z-index: 1; }

#callback-modal .modal-content {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }
  #callback-modal .modal-content .modal-header {
    border-bottom: none;
    border-radius: 0.3rem; }
    #callback-modal .modal-content .modal-header .modal-img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem; }
  #callback-modal .modal-content .modal-body form #label-success, #callback-modal .modal-content .modal-body form #label-warning {
    display: none; }
  #callback-modal .modal-content .modal-body form button[type="submit"] {
    font-size: 1rem; }
    #callback-modal .modal-content .modal-body form button[type="submit"] .spinner-border {
      display: none;
      width: 1rem;
      height: 1rem; }

.modal-backdrop {
  filter: blur(4px); }

.cart-indicator {
  display: flex;
  position: relative;
  transition: 0.3s; }
  .cart-indicator--mobile-collapse {
    margin: 0 auto; }
  .cart-indicator .cart-counter {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #e44a50;
    color: #FFFFFF;
    font-size: 0.875rem;
    font-weight: bold;
    transform: translate(50%, -50%);
    z-index: 10; }

@media screen and (max-width: 991.98px) {
  .cart-summary--mobile {
    display: none !important; } }

.cart-summary .item-thumbnail {
  width: 60px;
  height: 60px; }

.cart-summary .pricing-totals .pricing-total {
  display: flex;
  justify-content: space-between; }

.cart-summary .pricing-totals .shipping-total {
  font-size: 2.5rem; }

.cart-summary .pricing-totals .grand-total {
  color: #212529;
  font-size: 2.5rem;
  font-weight: bold; }

.product-highlight-banner .product-highlight-image__wrapper {
  width: 100%;
  height: 400px; }
  @media screen and (max-width: 767.98px) {
    .product-highlight-banner .product-highlight-image__wrapper {
      height: 180px; } }

.col--cart-summary .cart-items .cart-item {
  padding: 1.5rem 0;
  border-bottom: 1px solid rgba(33, 37, 41, 0.1); }
  .col--cart-summary .cart-items .cart-item:not(:first-of-type) {
    margin-top: 1rem; }
  .col--cart-summary .cart-items .cart-item .item-controls input[type="number"] {
    width: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 10px; }

.item-thumbnail {
  width: 60px;
  height: 60px; }

#item-summary {
  border-radius: 5px; }
  @media screen and (max-width: 991.98px) {
    #item-summary {
      display: none !important; } }
  #item-summary .item-price-quantity {
    display: flex;
    flex-wrap: wrap; }
    #item-summary .item-price-quantity p {
      position: relative; }
      #item-summary .item-price-quantity p:not(:last-of-type) {
        padding-right: 1rem; }
        #item-summary .item-price-quantity p:not(:last-of-type)::after {
          content: '';
          position: absolute;
          right: 0;
          width: 2px;
          height: 100%;
          background-color: #D8D8D8; }
      #item-summary .item-price-quantity p:not(:first-of-type) {
        padding-left: 1rem; }

.pricing-totals .pricing-total {
  display: flex;
  justify-content: space-between; }

.pricing-totals .shipping-total {
  font-size: 2.5rem; }

.pricing-totals .grand-total {
  font-size: 2rem;
  font-weight: bold; }

.product-overview--clothing h2 {
  font-family: "Bebas Neue" -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 3.5rem;
  font-weight: 700; }

body.product-toolbar-offset {
  margin-top: 75px; }
  @media screen and (max-width: 991.98px) {
    body.product-toolbar-offset {
      margin-top: 56px; } }

.product-toolbar.sticky-toolbar {
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  z-index: calc(1030 - 1) !important; }
  @media screen and (max-width: 991.98px) {
    .product-toolbar.sticky-toolbar {
      top: 56px; } }

.tour__itinerary {
  display: flex; }
  .tour__itinerary .itinerary-stages .itinerary-stage:not(:last-of-type) {
    margin-bottom: 4.5rem; }
  .tour__itinerary .itinerary-stages .itinerary-stage .stage__specs {
    display: flex;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase; }
    .tour__itinerary .itinerary-stages .itinerary-stage .stage__specs > div {
      display: flex;
      align-items: center;
      color: rgba(33, 37, 41, 0.8); }
      .tour__itinerary .itinerary-stages .itinerary-stage .stage__specs > div:not(:first-of-type) {
        margin-left: 1rem; }
      .tour__itinerary .itinerary-stages .itinerary-stage .stage__specs > div i {
        color: #00759f; }
  .tour__itinerary .itinerary-stages .itinerary-stage .stage__description p:last-of-type {
    margin-bottom: 0; }
  .tour__itinerary .itinerary-stages .itinerary-stage img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 10px; }
  .tour__itinerary .itinerary-nav {
    position: sticky;
    top: 0;
    max-height: 80vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 10px;
    overflow: scroll;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out; }
    .tour__itinerary .itinerary-nav.sticky-nav {
      box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
    @media screen and (max-width: 991.98px) {
      .tour__itinerary .itinerary-nav {
        display: none; } }
    @media screen and (min-width: 992px) {
      .tour__itinerary .itinerary-nav {
        display: flex;
        flex-direction: column;
        flex-grow: 0; } }
    .tour__itinerary .itinerary-nav .list-group__heading {
      border-top-left-radius: 10px; }
    .tour__itinerary .itinerary-nav .list-group-items {
      position: relative; }
      .tour__itinerary .itinerary-nav .list-group-items::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 14px;
        left: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.2) 100%); }
      .tour__itinerary .itinerary-nav .list-group-items .list-group-item {
        position: relative;
        border-radius: 0;
        background-color: rgba(0, 89, 121, 0.05);
        border-top-color: #FFFFFF;
        border-bottom-color: #FFFFFF;
        border-left: 0;
        border-right: 0;
        z-index: 2;
        overflow: visible; }
        .tour__itinerary .itinerary-nav .list-group-items .list-group-item:first-child {
          border-top: 0; }
        .tour__itinerary .itinerary-nav .list-group-items .list-group-item.active {
          position: relative;
          background-color: #e44a50 !important;
          border-color: #FFFFFF;
          border-left: 0;
          border-right: 0;
          color: #FFFFFF !important;
          font-weight: bold; }
          .tour__itinerary .itinerary-nav .list-group-items .list-group-item.active::before {
            transform: translate(-100%, 0); }
    .tour__itinerary .itinerary-nav .list-group__footer {
      border-bottom-right-radius: 10px; }
  .tour__itinerary .sticky-nav {
    position: fixed !important; }
    @media screen and (max-width: 991.98px) {
      .tour__itinerary .sticky-nav {
        display: none; } }
    @media screen and (min-width: 768px) {
      .tour__itinerary .sticky-nav {
        top: 75px; } }

.book-now__cta {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  z-index: 1030;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease; }

.tour__booking-options {
  border-width: 2px 0;
  border-color: #F45B69;
  border-style: solid; }
  .tour__booking-options .tour__booking-option-btn {
    display: flex;
    flex-direction: column;
    border: 2px solid #D8D8D8;
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    transition: 0.3s; }
    .tour__booking-options .tour__booking-option-btn:not(.unavailable):hover {
      background-color: #FAFAFA;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }
    .tour__booking-options .tour__booking-option-btn:not(.unavailable)[aria-expanded=true] {
      border: 2px solid #F45B69;
      background-color: #FAFAFA;
      box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
      transform: translateY(-5px); }
    .tour__booking-options .tour__booking-option-btn.unavailable {
      opacity: 0.5;
      pointer-events: none; }
    .tour__booking-options .tour__booking-option-btn h6 {
      color: #DA2128;
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase; }
    .tour__booking-options .tour__booking-option-btn p {
      color: #212529; }
      .tour__booking-options .tour__booking-option-btn p:nth-of-type(2) {
        font-weight: bold; }
    .tour__booking-options .tour__booking-option-btn .booking-option__icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 80px;
      margin-bottom: 1.5rem; }

#self-guided-booking-form h5 {
  margin: 1.5rem 0; }

#self-guided-booking-form input[type=tel], #self-guided-booking-form input[type=number], #self-guided-booking-form input[type=email],
#self-guided-booking-form input[type=text], #self-guided-booking-form textarea {
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px; }

.collapse-item {
  border: 0;
  border-radius: 0; }
  .collapse-item:not(:last-child) {
    border-bottom: 1px solid rgba(33, 37, 41, 0.2); }
  .collapse-item a {
    font-size: 1.25rem;
    text-decoration: none; }
    .collapse-item a i {
      transition: 0.2s; }
    .collapse-item a:hover {
      background-color: inherit; }
    .collapse-item a[aria-expanded=true] i {
      color: #005979 !important;
      transform: rotate(45deg); }
  .collapse-item .card {
    border: 0;
    border-radius: 0; }

.featured-tours__grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.75rem;
  min-height: 500px; }
  .featured-tours__grid .product__tile {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 10px; }
    @media screen and (max-width: 767.98px) {
      .featured-tours__grid .product__tile {
        height: 280px; } }
    .featured-tours__grid .product__tile:nth-child(1) {
      grid-column: 8 / 11;
      grid-row: 1 / 3; }
      @media screen and (max-width: 767.98px) {
        .featured-tours__grid .product__tile:nth-child(1) {
          grid-column: 1 / 11;
          grid-row: 1 / 2; } }
    .featured-tours__grid .product__tile:nth-child(2) {
      grid-column: 4 / 8;
      grid-row: 1 / 2; }
      @media screen and (max-width: 767.98px) {
        .featured-tours__grid .product__tile:nth-child(2) {
          grid-column: 1 / 11;
          grid-row: 2 / 3; } }
    .featured-tours__grid .product__tile:nth-child(3) {
      grid-column: 1 / 5;
      grid-row: 2 / 3; }
      @media screen and (max-width: 767.98px) {
        .featured-tours__grid .product__tile:nth-child(3) {
          grid-column: 1 / 11;
          grid-row: 3 / 4; } }
    .featured-tours__grid .product__tile:nth-child(4) {
      grid-column: 1 / 4;
      grid-row: 1 / 2; }
      @media screen and (max-width: 767.98px) {
        .featured-tours__grid .product__tile:nth-child(4) {
          grid-column: 1 / 11;
          grid-row: 4 / 5; } }
    .featured-tours__grid .product__tile:nth-child(5) {
      grid-column: 5 / 8;
      grid-row: 2 / 3; }
      @media screen and (max-width: 767.98px) {
        .featured-tours__grid .product__tile:nth-child(5) {
          grid-column: 1 / 11;
          grid-row: 5 / 6; } }

.form-check-input:disabled + .btn--pricing {
  color: rgba(0, 89, 121, 0.2) !important;
  border-color: rgba(0, 89, 121, 0.2); }
  .form-check-input:disabled + .btn--pricing:hover {
    background-color: unset; }

.form-check-input:not([disabled]) + .btn--pricing {
  cursor: pointer; }
  .form-check-input:not([disabled]) + .btn--pricing:hover {
    background-color: transparent;
    border-color: rgba(0, 89, 121, 0.7);
    color: rgba(0, 89, 121, 0.7); }

.form-check-input + .btn--pricing {
  color: rgba(0, 89, 121, 0.5);
  border-color: rgba(0, 89, 121, 0.5);
  border-width: 2px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase; }

.form-check-input:disabled + .btn--pricing {
  color: #6c757d; }

.form-check-input:checked + .btn--pricing {
  background-color: #005979 !important;
  color: #FFFFFF !important; }

.custom-checkbox {
  padding-left: 2.5rem; }
  .custom-checkbox--sm .custom-control-label {
    font-size: 0.675rem !important; }
    .custom-checkbox--sm .custom-control-label::before {
      width: 1rem !important;
      height: 1rem !important;
      font-size: 0.875rem !important; }
    .custom-checkbox--sm .custom-control-label::after {
      width: 1rem;
      height: 1rem;
      left: -2rem; }
  .custom-checkbox .custom-control-label {
    font-size: 0.875rem; }
    .custom-checkbox .custom-control-label::before {
      content: "\f00c";
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      left: -2.25rem;
      border: 2px solid #ced4da;
      color: transparent;
      font-family: "Font Awesome 5 Free";
      font-size: 1rem;
      font-style: normal;
      font-weight: 900;
      font-variant: normal;
      line-height: 1;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      pointer-events: none;
      transition: 0.3s; }
    .custom-checkbox .custom-control-label::after {
      width: 1.25rem;
      height: 1.25rem;
      top: 0;
      left: -2.25rem;
      background: none !important; }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    color: #e44a50;
    background-color: transparent;
    border: 2px solid #ced4da; }

.generic-banner {
  position: relative; }
  .generic-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(33, 37, 41, 0.3); }

#global-footer a {
  text-decoration: none; }

#global-footer .footer-brand {
  max-width: 140px; }
  #global-footer .footer-brand img {
    width: 100%;
    height: auto; }

#global-footer .footer__nav li a {
  color: #FFFFFF; }

#global-footer .footer__nav .nav__sub-list {
  min-width: 140px; }

#global-footer .footer__socials {
  min-width: 100px; }
  #global-footer .footer__socials .social-account:not(:first-of-type) {
    margin-left: 1rem; }

#global-footer .container--copyright {
  font-size: 80%; }
  #global-footer .container--copyright a {
    color: #FFFFFF; }

#global-footer .footer__euphoriq img {
  width: auto;
  height: 14px; }

#global-navbar {
  background-color: #005979;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 991.98px) {
    #global-navbar .navbar-brand {
      position: absolute;
      top: 0.5rem;
      left: 50%;
      transform: translate(-50%, 0); } }
  #global-navbar .navbar-brand img {
    width: auto;
    height: 50px;
    transition: 0.3s; }
    @media screen and (max-width: 991.98px) {
      #global-navbar .navbar-brand img {
        height: 40px; } }
  #global-navbar .navbar-collapse .navbar-nav .nav-link.active {
    color: rgba(255, 255, 255, 0.5) !important; }
  #global-navbar .navbar-collapse .navbar-nav .nav-link, #global-navbar .navbar-collapse .navbar-nav .dropdown-item {
    position: relative;
    font-size: 1.125rem;
    font-weight: 600; }
    #global-navbar .navbar-collapse .navbar-nav .nav-link ~ .dropdown-menu, #global-navbar .navbar-collapse .navbar-nav .dropdown-item ~ .dropdown-menu {
      background-color: #FAFAFA;
      overflow: hidden;
      border: 2px solid #005979;
      border-top-left-radius: 10px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 10px; }
      @media screen and (max-width: 991.98px) {
        #global-navbar .navbar-collapse .navbar-nav .nav-link ~ .dropdown-menu, #global-navbar .navbar-collapse .navbar-nav .dropdown-item ~ .dropdown-menu {
          background-color: #006c93;
          border: 2px solid #FFFFFF; } }
      @media screen and (min-width: 992px) {
        #global-navbar .navbar-collapse .navbar-nav .nav-link ~ .dropdown-menu, #global-navbar .navbar-collapse .navbar-nav .dropdown-item ~ .dropdown-menu {
          transform: translateY(20px);
          box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); } }
      #global-navbar .navbar-collapse .navbar-nav .nav-link ~ .dropdown-menu .dropdown-item, #global-navbar .navbar-collapse .navbar-nav .dropdown-item ~ .dropdown-menu .dropdown-item {
        color: #005979; }
        @media screen and (max-width: 575.98px) {
          #global-navbar .navbar-collapse .navbar-nav .nav-link ~ .dropdown-menu .dropdown-item, #global-navbar .navbar-collapse .navbar-nav .dropdown-item ~ .dropdown-menu .dropdown-item {
            white-space: initial; } }
        @media screen and (max-width: 991.98px) {
          #global-navbar .navbar-collapse .navbar-nav .nav-link ~ .dropdown-menu .dropdown-item, #global-navbar .navbar-collapse .navbar-nav .dropdown-item ~ .dropdown-menu .dropdown-item {
            color: #FFFFFF; } }
        #global-navbar .navbar-collapse .navbar-nav .nav-link ~ .dropdown-menu .dropdown-item.active, #global-navbar .navbar-collapse .navbar-nav .dropdown-item ~ .dropdown-menu .dropdown-item.active {
          background-color: transparent;
          font-weight: bold; }
        #global-navbar .navbar-collapse .navbar-nav .nav-link ~ .dropdown-menu .dropdown-item.coming-soon, #global-navbar .navbar-collapse .navbar-nav .dropdown-item ~ .dropdown-menu .dropdown-item.coming-soon {
          position: relative;
          color: rgba(0, 89, 121, 0.25) !important; }
          @media screen and (max-width: 991.98px) {
            #global-navbar .navbar-collapse .navbar-nav .nav-link ~ .dropdown-menu .dropdown-item.coming-soon, #global-navbar .navbar-collapse .navbar-nav .dropdown-item ~ .dropdown-menu .dropdown-item.coming-soon {
              color: rgba(255, 255, 255, 0.25) !important; } }
        #global-navbar .navbar-collapse .navbar-nav .nav-link ~ .dropdown-menu .dropdown-item:hover, #global-navbar .navbar-collapse .navbar-nav .dropdown-item ~ .dropdown-menu .dropdown-item:hover {
          color: rgba(0, 89, 121, 0.8);
          background-color: transparent; }
          @media screen and (max-width: 991.98px) {
            #global-navbar .navbar-collapse .navbar-nav .nav-link ~ .dropdown-menu .dropdown-item:hover, #global-navbar .navbar-collapse .navbar-nav .dropdown-item ~ .dropdown-menu .dropdown-item:hover {
              color: rgba(255, 255, 255, 0.25) !important; } }
  #global-navbar .nav-link--motif:not(.dropdown-item) {
    position: relative; }
    #global-navbar .nav-link--motif:not(.dropdown-item).dropdown-toggle::before, #global-navbar .nav-link--motif:not(.dropdown-item).dropdown-toggle::after {
      display: none; }

.hamburger {
  height: 24px;
  padding: 0;
  display: flex;
  outline: 0 !important;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  @media screen and (min-width: 992px) {
    .hamburger {
      display: none; } }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000; }

.hamburger-box {
  width: 30px;
  height: 18px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 2px;
    background-color: #FFFFFF;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -8px; }
  .hamburger-inner::after {
    bottom: -8px; }

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -16px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse[aria-expanded=true] .hamburger-inner {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse[aria-expanded=true] .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse[aria-expanded=true] .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hero .hero__content, .hero .item__content {
  width: 50%; }
  .hero .hero__content > h1, .hero .item__content > h1 {
    text-shadow: 0 0 4px rgba(33, 37, 41, 0.8); }
  .hero .hero__content > p, .hero .item__content > p {
    line-height: 1.4;
    text-shadow: 0 0 3px #212529; }
  @media screen and (max-width: 575.98px) {
    .hero .hero__content, .hero .item__content {
      width: calc(100% - (2 * 1.5rem)); }
      .hero .hero__content h1, .hero .item__content h1 {
        font-size: 2.5rem; }
      .hero .hero__content p, .hero .item__content p {
        font-size: 1rem; } }
  @media screen and (min-width: 576px) and (max-width: 1199.98px) {
    .hero .hero__content, .hero .item__content {
      width: calc(75% - 1.5rem); }
      .hero .hero__content h1, .hero .item__content h1 {
        font-size: 5rem; } }

.hero--header {
  position: relative;
  height: 500px; }
  .hero--header::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e44a50;
    background: linear-gradient(0deg, rgba(33, 37, 41, 0.8) 0%, rgba(33, 37, 41, 0) 60%);
    z-index: 0; }
  .hero--header .hero__content {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 1; }

.hero--carousel {
  height: calc(100vh - 75px);
  min-height: 400px;
  max-height: 720px; }
  @media screen and (max-width: 767.98px) {
    .hero--carousel {
      height: calc(60vh - 56px);
      max-height: 550px; } }
  .hero--carousel .carousel-item {
    position: relative; }
    .hero--carousel .carousel-item::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.5) 100%);
      z-index: 0; }
    .hero--carousel .carousel-item .item__content {
      position: absolute;
      bottom: 3rem;
      left: 3rem;
      height: auto;
      z-index: 1; }
      @media screen and (max-width: 767.98px) {
        .hero--carousel .carousel-item .item__content {
          bottom: calc(3rem + 1rem);
          left: 1.5rem; } }

.carousel-indicators {
  justify-content: flex-end;
  margin-right: 3rem;
  margin-bottom: 3rem; }
  @media screen and (max-width: 767.98px) {
    .carousel-indicators {
      justify-content: flex-start;
      margin-left: 1.5rem;
      margin-right: 0;
      margin-bottom: 1.5rem; } }
  .carousel-indicators li {
    width: 12px;
    height: 12px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #FFFFFF; }
    .carousel-indicators li.active {
      height: 16px;
      width: 16px;
      background-color: #FFFFFF;
      border: none; }

.hero--product-showcase {
  position: relative;
  overflow-y: hidden; }
  @media screen and (min-width: 992px) {
    .hero--product-showcase {
      height: calc(80vh - 82px);
      min-height: 650px;
      max-height: 800px; }
      .hero--product-showcase::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.75) 95%); } }
  @media screen and (max-width: 991.98px) {
    .hero--product-showcase {
      min-height: 300px !important; } }
  .hero--product-showcase .hero-sidebar {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 100%;
    box-shadow: 9px 10px 30px 0px rgba(0, 0, 0, 0.65);
    background-color: #FFFFFF;
    transition: 0.5s;
    z-index: 2 !important; }
    @media screen and (max-width: 991.98px) {
      .hero--product-showcase .hero-sidebar {
        position: relative;
        width: 100% !important; } }
    .hero--product-showcase .hero-sidebar .showcase-body {
      z-index: 2 !important; }
      .hero--product-showcase .hero-sidebar .showcase-body + .sidebar-close {
        position: absolute;
        top: 50%;
        right: 1px;
        height: 48px;
        width: 30px;
        border-width: 0;
        border-radius: 0 5px 5px 0;
        background-color: #FFFFFF;
        transform: translate(100%, -50%);
        z-index: 1 !important; }
        @media screen and (max-width: 991.98px) {
          .hero--product-showcase .hero-sidebar .showcase-body + .sidebar-close {
            display: none; } }
  .hero--product-showcase .sidebar-open {
    display: none;
    position: absolute;
    left: 3rem;
    bottom: 3rem;
    z-index: 1 !important;
    transition: 0.4s; }
    .hero--product-showcase .sidebar-open.active {
      display: block; }
  .hero--product-showcase .owl-carousel__container {
    position: relative;
    display: flex; }
    @media screen and (max-width: 991.98px) {
      .hero--product-showcase .owl-carousel__container {
        height: 400px !important; } }
    @media screen and (max-width: 575.98px) {
      .hero--product-showcase .owl-carousel__container {
        height: 260px !important; } }
    .hero--product-showcase .owl-carousel__container .owl-carousel__indicators {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      bottom: 1rem;
      right: 0;
      z-index: 1; }
      @media screen and (max-width: 767.98px) {
        .hero--product-showcase .owl-carousel__container .owl-carousel__indicators {
          left: 0;
          bottom: 0; } }
      .hero--product-showcase .owl-carousel__container .owl-carousel__indicators button {
        appearance: none;
        display: inline-block;
        width: 16px;
        height: 16px;
        padding: 0 !important;
        margin-right: 0.25rem;
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid #FFFFFF; }
        @media screen and (max-width: 767.98px) {
          .hero--product-showcase .owl-carousel__container .owl-carousel__indicators button {
            width: 10px;
            height: 10px; } }
        .hero--product-showcase .owl-carousel__container .owl-carousel__indicators button.active {
          background-color: #FFFFFF; }
    .hero--product-showcase .owl-carousel__container .hero-carousel {
      position: relative;
      width: 100%;
      z-index: 0; }
      .hero--product-showcase .owl-carousel__container .hero-carousel::before {
        content: '';
        position: absolute;
        width: 300px;
        height: 300px;
        top: 50%;
        left: 50%;
        background-image: url("../img/fmt-logo-red.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        opacity: 0.5;
        transform: translate(-50%, -50%); }
      .hero--product-showcase .owl-carousel__container .hero-carousel .owl-stage-outer, .hero--product-showcase .owl-carousel__container .hero-carousel .owl-stage-outer * {
        height: 100%; }
      .hero--product-showcase .owl-carousel__container .hero-carousel .carousel-slide {
        height: 100%; }
        @media screen and (max-width: 991.98px) {
          .hero--product-showcase .owl-carousel__container .hero-carousel .carousel-slide {
            max-height: 400px !important; } }
  .hero--product-showcase .showcase__details .badge {
    padding: 0.5rem 1rem;
    color: #FFFFFF; }
    .hero--product-showcase .showcase__details .badge.badge__duration {
      background-color: rgba(0, 89, 121, 0.6); }
    .hero--product-showcase .showcase__details .badge.badge__skill {
      background-color: #005979; }

main.commerce, main.formThanks {
  display: flex; }
  main.commerce .hero--order, main.commerce .hero--thanks, main.formThanks .hero--order, main.formThanks .hero--thanks {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1; }
    main.commerce .hero--order::before, main.commerce .hero--thanks::before, main.formThanks .hero--order::before, main.formThanks .hero--thanks::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba(0, 1, 17, 0.3) 0%, rgba(125, 185, 232, 0) 50%);
      z-index: 0; }
    main.commerce .hero--order .hero__content, main.commerce .hero--thanks .hero__content, main.formThanks .hero--order .hero__content, main.formThanks .hero--thanks .hero__content {
      z-index: 1; }
      main.commerce .hero--order .hero__content i, main.commerce .hero--thanks .hero__content i, main.formThanks .hero--order .hero__content i, main.formThanks .hero--thanks .hero__content i {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4); }

.intro-block .intro-block__img {
  object-fit: cover;
  max-height: 600px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px; }
  @media screen and (max-width: 767.98px) {
    .intro-block .intro-block__img {
      max-height: 400px; } }

.intro-block .intro-block__body h1,
.intro-block .intro-block__body h2,
.intro-block .intro-block__body h3,
.intro-block .intro-block__body h4,
.intro-block .intro-block__body h5,
.intro-block .intro-block__body h6 {
  font-weight: 600;
  text-transform: uppercase; }
  .intro-block .intro-block__body h1:not(:first-of-type),
  .intro-block .intro-block__body h2:not(:first-of-type),
  .intro-block .intro-block__body h3:not(:first-of-type),
  .intro-block .intro-block__body h4:not(:first-of-type),
  .intro-block .intro-block__body h5:not(:first-of-type),
  .intro-block .intro-block__body h6:not(:first-of-type) {
    margin-top: 3rem; }

.intro-block .intro-block__ctas a {
  font-weight: bold; }

.fmt-list li, .fmt-list div {
  position: relative;
  list-style: none;
  padding: 0.5rem 0; }
  .fmt-list li::before, .fmt-list div::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: calc(1rem + 5px);
    left: -1.1225em;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #e44a50;
    transform: translateY(-50%); }

.list-block .list-group-item:first-child {
  border-top: 0; }

.list-block .list-group-item .display-4 {
  height: 2.875rem;
  line-height: 1; }

.lds-roller {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.4); }

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px; }

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px; }

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s; }

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px; }

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s; }

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px; }

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s; }

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px; }

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s; }

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px; }

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s; }

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px; }

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s; }

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px; }

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s; }

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px; }

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s; }

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px; }

@keyframes lds-roller {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.product-overview .overview-body h1,
.product-overview .overview-body h2,
.product-overview .overview-body h3,
.product-overview .overview-body h4,
.product-overview .overview-body h5,
.product-overview .overview-body h6 {
  margin-bottom: 1rem; }

.product-overview .overview-body p:last-child {
  margin-bottom: 0; }

.product-overview .overview-body .product-highlights {
  list-style: none; }
  @media screen and (min-width: 992px) {
    .product-overview .overview-body .product-highlights {
      column-count: 2; } }

.product__tile {
  min-height: 200px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  transition: 0.25s ease-in-out !important; }
  .product__tile--fallback {
    opacity: 0.7; }
    .product__tile--fallback p {
      margin: 0 auto; }
    @media screen and (max-width: 991.98px) {
      .product__tile--fallback {
        display: none !important; } }
    .product__tile--fallback .fallback-img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .product__tile .tour-tile__content {
    position: relative; }
    .product__tile .tour-tile__content--fully-booked *:not(.marker--booked) {
      opacity: 0.75; }
    .product__tile .tour-tile__content--fully-booked .marker--booked {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 2; }
    .product__tile .tour-tile__content::after {
      content: "";
      position: absolute;
      top: -1rem;
      left: -1rem;
      width: calc(100% + (2 * 1rem));
      height: calc(100% + (2 * 1rem));
      border-top-left-radius: 10px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 10px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.2) 60%, rgba(0, 0, 0, 0.5) 100%);
      opacity: 0.4;
      z-index: 0;
      transition: 0.3s; }
      @media screen and (max-width: 767.98px) {
        .product__tile .tour-tile__content::after {
          opacity: 0.4; } }
    .product__tile .tour-tile__content .badge--skill {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.25rem 0.5rem;
      border: 1px solid #FFFFFF;
      background-color: rgba(255, 255, 255, 0.3);
      color: #FFFFFF;
      text-transform: uppercase;
      font-weight: 600;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      transition: 0.2s ease-in-out;
      z-index: 1; }
    .product__tile .tour-tile__content .tour__details {
      transition: 0.4s;
      z-index: 1;
      text-shadow: 0 0 3px #212529; }
      .product__tile .tour-tile__content .tour__details .tile-title {
        font-weight: 600; }
    .product__tile .tour-tile__content .content-reveal {
      position: absolute;
      bottom: 0;
      left: 0;
      text-shadow: 0 0 3px #212529;
      opacity: 0;
      transform-origin: bottom center;
      transition: 0.4s;
      z-index: 1; }
      .product__tile .tour-tile__content .content-reveal p {
        line-height: 1.25; }
  .product__tile:hover {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px); }
    .product__tile:hover.product__tile--hover .tour-tile__content::after {
      opacity: 1; }
    .product__tile:hover.product__tile--hover.product__tile--fallback {
      background: blue !important; }
    .product__tile:hover.product__tile--hover .tour__details {
      opacity: 0; }
    .product__tile:hover.product__tile--hover .content-reveal {
      opacity: 1; }
    .product__tile:hover .badge--skill {
      background-color: #e44a50; }

.quote-block {
  position: relative; }
  .quote-block .quote-content::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 0; }
  .quote-block .quote-content .quote {
    position: relative;
    z-index: 1; }
    @media screen and (max-width: 991.98px) {
      .quote-block .quote-content .quote {
        font-size: 1.75rem !important; } }
  .quote-block .quote-content .quote-details {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1; }

.quote-slider #testimonial-carousel .carousel-item .col--image {
  min-height: 400px; }
  @media screen and (min-width: 1200px) {
    .quote-slider #testimonial-carousel .carousel-item .col--image {
      min-height: 600px; } }

.social-banner {
  display: flex;
  align-items: center;
  position: relative; }
  @media screen and (min-width: 992px) {
    .social-banner {
      min-height: 300px; } }
  .social-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(228, 74, 80, 0.8);
    z-index: 0; }
  .social-banner .row {
    z-index: 1; }
  .social-banner .social-banner__social-links {
    margin-top: 1.5rem; }
  .social-banner__link:not(:first-of-type) {
    margin-left: 3rem; }
  .social-banner__link i {
    transition: 0.2s; }
  .social-banner__link:hover i {
    color: #d9d9d9 !important; }

@media screen and (max-width: 767.98px) {
  .split-blocks .split-block {
    flex-direction: column; } }

@media screen and (min-width: 768px) {
  .split-blocks .split-block:nth-child(even) {
    flex-direction: row-reverse; } }

@media screen and (max-width: 767.98px) {
  .split-blocks .split-block .block__image {
    height: 300px; } }

@media screen and (min-width: 1200px) {
  .split-blocks .split-block .block__image {
    max-height: 500px; } }

.team-members {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .team-members .team-member__avatar {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    border: 3px solid #005979;
    border-radius: 50%;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    cursor: pointer; }
    @media screen and (max-width: 767.98px) {
      .team-members .team-member__avatar[aria-expanded="false"] + div {
        margin-bottom: 3rem; } }
    .team-members .team-member__avatar:hover, .team-members .team-member__avatar[aria-expanded="true"] {
      box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
      transform: translateY(-2px); }
      .team-members .team-member__avatar:hover .avatar-img, .team-members .team-member__avatar[aria-expanded="true"] .avatar-img {
        filter: none; }
    .team-members .team-member__avatar[aria-expanded="true"], .team-members .team-member__avatar .active {
      width: 160px;
      height: 160px;
      border: 3px solid #e44a50; }
      .team-members .team-member__avatar[aria-expanded="true"] .avatar-img, .team-members .team-member__avatar .active .avatar-img {
        filter: none; }
    .team-members .team-member__avatar .avatar-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-size: cover;
      filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); }

.team-member-bio {
  margin-top: 1.5rem;
  border-radius: 5px;
  background-color: #005979;
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
  opacity: 0;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  transition: 0.3s; }
  .team-member-bio.show {
    opacity: 1;
    height: auto; }
  .team-member-bio:not(:last-of-type) {
    margin-bottom: 1.5rem; }
  .team-member-bio .bio-block {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 992px) {
      .team-member-bio .bio-block:nth-child(odd) {
        flex-direction: row-reverse; } }
    .team-member-bio .bio-block:nth-child(even) {
      text-align: right; }
      @media screen and (min-width: 992px) {
        .team-member-bio .bio-block:nth-child(even) {
          flex-direction: row-reverse; } }
    .team-member-bio .bio-block .block-text > *:last-child {
      margin-bottom: 0; }
  .team-member-bio .bio-content .block-img {
    border: 2px solid #FFFFFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

main.contact-us .contact-block:not(:first-of-type) {
  margin-top: 3rem; }

main.contact-us #contact-form input, main.contact-us #contact-form textarea {
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px; }

main.contact-us #contact-form .smallprint a {
  font-weight: 600; }

main.contact-us .address-info p {
  margin-bottom: 0; }

.cart-form .col--pricing-totals .pricing-totals {
  border-radius: 5px; }

.cart-form .col--cart-summary .cart-summary {
  border-radius: 5px; }

.commerce .order-item {
  position: relative;
  padding-bottom: 1rem; }
  .commerce .order-item:not(:last-of-type) {
    border-bottom: 2px solid #FAFAFA;
    margin-bottom: 1rem; }
  .commerce .order-item .remove-item-form:not(.remove-item-form--btn-link) {
    position: absolute;
    top: 0;
    right: 0; }
  .commerce .order-item .remove-item-form--btn-link .btn-link {
    font-weight: 600; }
  .commerce .order-item:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(216, 216, 216, 0.5); }

.commerce .checkout-progress .progress-indicator {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  text-align: center;
  font-size: 2rem;
  border-radius: 50%;
  opacity: 0.4;
  transition: 0.2s; }
  @media screen and (max-width: 767.98px) {
    .commerce .checkout-progress .progress-indicator {
      height: 40px;
      width: 40px;
      font-size: 1.5rem; } }
  .commerce .checkout-progress .progress-indicator:not(:last-of-type) {
    margin-right: 0.25rem; }
  .commerce .checkout-progress .progress-indicator.active {
    opacity: 1;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }

.commerce .item-summary-mobile {
  border-radius: 5px; }
  @media screen and (min-width: 992px) {
    .commerce .item-summary-mobile {
      display: none !important; } }
  .commerce .item-summary-mobile .item-thumbnail {
    width: 60px;
    height: 60px; }
  .commerce .item-summary-mobile .remove-item-form {
    position: relative; }
  .commerce .item-summary-mobile .pricing-totals .pricing-total {
    display: flex;
    justify-content: space-between; }
  .commerce .item-summary-mobile .pricing-totals .shipping-total {
    font-size: 1.25rem; }
  .commerce .item-summary-mobile .pricing-totals .grand-total {
    font-size: 1.25rem;
    font-weight: bold; }

.commerce .additional-information {
  border: 2px solid #D8D8D8; }

.commerce .order-summary .order-section__details p {
  margin-bottom: 0.5rem; }

.commerce .additional-riders .additional-rider-details:not(:first-of-type) {
  padding-top: 1.5rem; }

.commerce #payment-card-form {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 220px;
  padding: 1.5rem;
  border-radius: 10px;
  color: #FFFFFF;
  background-color: #005979;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 767.98px) {
    .commerce #payment-card-form {
      width: calc(100% + (2 * 1rem));
      margin-left: -1rem;
      height: 0;
      padding-bottom: 60%; } }
  .commerce #payment-card-form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(0, 89, 121, 0.5);
    z-index: 0; }
  .commerce #payment-card-form .fmt-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 180px;
    height: auto;
    z-index: 1; }
    @media screen and (max-width: 767.98px) {
      .commerce #payment-card-form .fmt-icon {
        top: 15px;
        left: 15px;
        width: 140px;
        height: auto; } }
  .commerce #payment-card-form .card-errors {
    width: calc(100% - 100px); }
  .commerce #payment-card-form .card-element {
    position: absolute;
    top: 70%;
    left: 50%;
    width: calc(100% - (1rem * 2));
    height: auto;
    transform: translate(-50%, -50%);
    -webkit-font-smoothing: antialiased !important; }

main.clothing .products--clothing .product--clothing .product-img img {
  max-height: 400px; }
  main.clothing .products--clothing .product--clothing .product-img img.fallback-img {
    opacity: 0.3;
    width: 100%;
    height: auto;
    max-width: 400px; }

main.clothing .hero--product-showcase {
  border-bottom: 5px solid #e44a50; }
  main.clothing .hero--product-showcase .form-check .form-check-input:checked ~ .form-check-label {
    background-color: #005979;
    color: #FFFFFF; }
  main.clothing .hero--product-showcase .owl-item img.carousel-slide {
    background-color: #FFFFFF;
    padding: 1.5rem 0; }
    @media screen and (min-width: 992px) {
      main.clothing .hero--product-showcase .owl-item img.carousel-slide {
        padding: 3rem 0;
        width: 75%;
        margin-left: auto;
        transition: 0.3s; } }
    main.clothing .hero--product-showcase .owl-item img.carousel-slide.full-width {
      width: 100%; }

main.clothing .featured-tours-block {
  background-color: #FAFAFA !important; }

main.commerce {
  background-color: #FAFAFA; }

.motorcycle-tours .hero--product-showcase .carousel-slide {
  object-fit: cover; }

@media screen and (min-width: 992px) {
  .motorcycle-tours .pricing__options .single-room {
    border-right: 1px solid rgba(0, 0, 0, 0.1); } }

@media screen and (min-width: 992px) {
  .motorcycle-tours .pricing__options .shared-room {
    padding-left: 1.5rem; } }

.motorcycle-tours .pricing__options hr {
  border: 1px solid rgba(0, 0, 0, 0.15); }

.motorcycle-tours .pricing__options .pricing__summary {
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  transition: 0.2s; }
  .motorcycle-tours .pricing__options .pricing__summary:hover {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }

.tour__details ul {
  column-count: 2; }

.tour__details sup {
  color: #DA2128;
  font-weight: 600; }

main.error {
  display: flex; }
  main.error .error-code {
    font-family: "Bebas Neue" -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 12rem;
    line-height: 1; }

main.faqs .faq-category:not(:first-child) {
  margin-top: 3rem; }

main.faqs .faq-category:last-child {
  margin-bottom: 3rem; }

main.faqs .faq-category .faq-item {
  border: 0;
  border-bottom: 1px solid rgba(33, 37, 41, 0.2);
  border-radius: 0; }
  main.faqs .faq-category .faq-item .card-header {
    background-color: transparent;
    border: 0;
    border-radius: 0; }
    main.faqs .faq-category .faq-item .card-header button {
      font-size: 1.25rem; }
      main.faqs .faq-category .faq-item .card-header button i {
        transition: 0.2s; }
      main.faqs .faq-category .faq-item .card-header button[aria-expanded=true] i {
        color: #005979 !important;
        transform: rotate(45deg); }

@media screen and (max-width: 575.98px) {
  main.motorcycleToursLanding .product__tile {
    height: 300px; } }

main.motorcycleToursLanding .product__tile .tour-tile__content::after {
  background: linear-gradient(to bottom, rgba(33, 47, 41, 0.5) 0%, rgba(30, 87, 153, 0) 20%, rgba(30, 87, 153, 0) 60%, rgba(33, 47, 41, 0.7) 100%); }

.tours__year-block .product__tile {
  height: 410px; }

main.policies .policy-block h1, main.policies .policy-block h2, main.policies .policy-block h3, main.policies .policy-block h4, main.policies .policy-block h5, main.policies .policy-block h6 {
  font-weight: 600;
  margin-bottom: 1rem; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }
