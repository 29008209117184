#global-navbar {
  background-color: $primary;
  @include box-shadow(8);

  .navbar-brand {
    @media screen and (max-width: breakpoint-max(md)) {
      position: absolute;
      top: map_get($spacers, 2);
      left: 50%;
      transform: translate(-50%, 0);
    }

    img {
      width: auto;
      height: 50px;
      transition: 0.3s;

      @media screen and (max-width: breakpoint-max(md)) {
        height: 40px;
      }
    }
  }

  .navbar-collapse {
    .navbar-nav {
      .nav-link {
        &.active {
          color: transparentize($white, 0.5) !important;
        }
      }

      .nav-link, .dropdown-item {
        position: relative;
        font-size: $font-size-base * 1.125;
        font-weight: 600;

        ~ .dropdown-menu {
          background-color: $pale-grey;
          overflow: hidden;
          border: 2px solid $primary;
          @include fmt-borders;

          @media screen and (max-width: breakpoint-max(md)) {
            background-color: lighten($primary, 5%);
            border: 2px solid $white;
          }

          @media screen and (min-width: breakpoint-min(lg)) {
            transform: translateY(20px);
            @include box-shadow(8);
          }

          .dropdown-item {
            color: $primary;

            @media screen and (max-width: breakpoint-max(xs)) {
              white-space: initial;
            }

            @media screen and (max-width: breakpoint-max(md)) {
              color: $white;
            }

            &.active {
              background-color: transparent;
              font-weight: bold;
            }

            &.coming-soon {
              position: relative;
              color: transparentize($primary, 0.75) !important;

              @media screen and (max-width: breakpoint-max(md)) {
                color: transparentize($white, 0.75) !important;
              }
            }

            &:hover {
              color: transparentize($primary, 0.2);
              background-color: transparent;

              @media screen and (max-width: breakpoint-max(md)) {
                color: transparentize($white, 0.75) !important;
              }
            }
          }
        }
      }
    }
  }

  .nav-link--motif:not(.dropdown-item) {
    position: relative;

    &.dropdown-toggle {
      &::before, &::after {
        display: none;
      }
    }
  }
}

.hamburger {
  height: 24px;
  padding: 0;
  display: flex;
  outline: 0 !important;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  @media screen and (min-width: breakpoint-min(lg)) {
    display: none;
  }

  &:hover {
    opacity: $hamburger-hover-opacity;
  }

  &.is-active {
    &:hover {
      opacity: $hamburger-active-hover-opacity;
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $white;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}


.hamburger--collapse {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::after {
      top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      opacity 0.1s linear;
    }

    &::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
      transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &[aria-expanded=true] {
    .hamburger-inner {
      transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(-45deg);
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        opacity 0.1s 0.22s linear;
      }

      &::before {
        top: 0;
        transform: rotate(-90deg);
        transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}