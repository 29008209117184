.intro-block {
  .intro-block__img {
    object-fit: cover;
    max-height: 600px;
    @include fmt-borders;

    @media screen and (max-width: breakpoint-max(sm)) {
      max-height: 400px;
    }
  }

  .intro-block__body {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
      text-transform: uppercase;

      &:not(:first-of-type) {
        margin-top: map_get($spacers, 5);
      }
    }
  }

  .intro-block__ctas {
    a {
      font-weight: bold;
    }
  }
}