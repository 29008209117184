.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  @include box-shadow(4);

  &--sm {
    width: 80px;
    height: 80px;
  }

  img {
    border: 3px solid $white;
    border-radius: 50%;
  }
}