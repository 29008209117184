.quote-block {
  position: relative;

  .quote-content {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: transparentize($black, 0.8);
      z-index: 0;
    }

    .quote {
      position: relative;
      z-index: 1;

      @media screen and (max-width: breakpoint-max(md)) {
        font-size: $h3-font-size !important;
      }
    }

    .quote-details {
      position: relative;
      display: flex;
      align-items: center;
      z-index: 1;
    }
  }
}