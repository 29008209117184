.form-check-input {
  &:disabled {
    + .btn--pricing {
      color: transparentize($primary, 0.8) !important;
      border-color: transparentize($primary, 0.8);

      &:hover {
        background-color: unset;
      }
    }
  }

  &:not([disabled]) {
    + .btn--pricing {
      cursor: pointer;

      &:hover {
        background-color: transparent;
        border-color: transparentize($primary, 0.3);
        color: transparentize($primary, 0.3);
      }
    }
  }

  + .btn--pricing {
    color: transparentize($primary, 0.5);
    border-color: transparentize($primary, 0.5);
    border-width: 2px;
    @include fmt-borders;
    font-size: $font-size-sm;
    font-weight: bold;
    text-transform: uppercase;
  }

  &:disabled + .btn--pricing {
    color: $text-muted;
  }

  &:checked + .btn--pricing {
    background-color: $primary !important;
    color: $white !important;
  }
}

.custom-checkbox {
  padding-left: $spacer * 2.5;

  &--sm {
    .custom-control-label {
      font-size: $font-size-base * 0.675 !important;

      // Custom tick icon
      &::before {
        width: 1rem !important;
        height: 1rem !important;
        font-size: $font-size-base * 0.875 !important;
      }

      &::after {
        width: 1rem;
        height: 1rem;
        left: -2rem;
      }
    }
  }

  .custom-control-label {
    font-size: $font-size-sm;

    // Custom tick icon
    &::before {
      content: "\f00c";
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      left: -2.25rem;
      border: $input-border-width solid $gray-400;
      color: transparent;
      font-family: "Font Awesome 5 Free";
      font-size: 1rem;
      font-style: normal;
      font-weight: 900;
      font-variant: normal;
      line-height: 1;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      pointer-events: none;
      transition: 0.3s;
    }

    &::after {
      width: 1.25rem;
      height: 1.25rem;
      top: 0;
      left: -2.25rem;
      background: none !important;
    }
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          color: $secondary;
          background-color: transparent;
          border: $input-border-width solid $gray-400;
        }
      }
    }
  }
}