body.product-toolbar-offset {
  margin-top: $navbar-height;

  @media screen and (max-width: breakpoint-max(md)) {
    margin-top: $navbar-height-mobile;
  }
}

.product-toolbar {
  &.sticky-toolbar {
    position: fixed;
    top: $navbar-height;
    left: 0;
    width: 100%;
    z-index: calc(#{$zindex-fixed} - 1) !important;

    @media screen and (max-width: breakpoint-max(md)) {
      top: $navbar-height-mobile;
    }
  }
}