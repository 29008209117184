// Based on box-shadow depths 'dp' recommended by Google Material Design

@mixin box-shadow($dp) {
  @if $dp==0 {
    box-shadow: none
  }
  @else if $dp==1 {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12), 0 1px 3px 0 rgba(0, 0, 0, .20)
  }
  @else if $dp==2 {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .20)
  }
  @else if $dp==3 {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .12), 0 1px 8px 0 rgba(0, 0, 0, .20)
  }
  @else if $dp==4 {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .20)
  }
  @else if $dp==6 {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .20)
  }
  @else if $dp==8 {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .20)
  }
  @else if $dp==9 {
    box-shadow: 0 9px 12px 1px rgba(0, 0, 0, .14), 0 3px 16px 2px rgba(0, 0, 0, .12), 0 5px 6px -3px rgba(0, 0, 0, .20)
  }
  @else if $dp==12 {
    box-shadow: 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12), 0 7px 8px -4px rgba(0, 0, 0, .20)
  }
  @else if $dp==16 {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .20)
  }
  @else if $dp==24 {
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12), 0 11px 15px -7px rgba(0, 0, 0, .20)
  }
}

@mixin box-shadow-inset($dp) {
  @if $dp==0 {
    box-shadow: none
  }
  @else if $dp==1 {
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, .14), inset 0 2px 1px -1px rgba(0, 0, 0, .12), inset 0 1px 3px 0 rgba(0, 0, 0, .20)
  }
  @else if $dp==2 {
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, .14), inset 0 3px 1px -2px rgba(0, 0, 0, .12), inset 0 1px 5px 0 rgba(0, 0, 0, .20)
  }
  @else if $dp==3 {
    box-shadow: inset 0 3px 4px 0 rgba(0, 0, 0, .14), inset 0 3px 3px -2px rgba(0, 0, 0, .12), inset 0 1px 8px 0 rgba(0, 0, 0, .20)
  }
  @else if $dp==4 {
    box-shadow: inset 0 4px 5px 0 rgba(0, 0, 0, .14), inset 0 1px 10px 0 rgba(0, 0, 0, .12), inset 0 2px 4px -1px rgba(0, 0, 0, .20)
  }
  @else if $dp==6 {
    box-shadow: inset 0 6px 10px 0 rgba(0, 0, 0, .14), inset 0 1px 18px 0 rgba(0, 0, 0, .12), inset 0 3px 5px -1px rgba(0, 0, 0, .20)
  }
}


/* Google Material Design - Component shadow guidelines

Default elevation values (dp)

Dialog - 24dp
Modal bottom sheet, Modal side sheet - 16dp
Navigation drawer - 16dp
Floating action button (FAB - pressed) - 12dp
Standard bottom sheet, Standard side sheet - 8dp
Bottom navigation bar - 8dp
Bottom app bar - 8dp
Menus and sub menus - 8dp
Card (when picked up) - 8dp
Contained button (pressed state) - 8dp
Floating action button (FAB - resting elevation), Snackbar - 6dp
Top app bar (scrolled state) - 4dp
Top app bar (resting elevation) - 0dp or 4dp
Refresh indicator, Search bar (scrolled state) - 3dp
Contained button (resting elevation) - 2dp
Search bar (resting elevation) - 1dp
Card (resting elevation) - 1dp
Switch - 1dp
Text button - 0dp
Standard side sheet - 0dp

*/