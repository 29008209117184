.team-members {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .team-member__avatar {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    border: 3px solid $primary;
    border-radius: 50%;
    @include box-shadow(4);
    transition: 0.3s;
    cursor: pointer;


    &[aria-expanded="false"] {
      + div {
        @media screen and (max-width: breakpoint-max(sm)) {
          margin-bottom: map_get($spacers, 5);
        }
      }
    }

    &:hover, &[aria-expanded="true"] {
      @include box-shadow(8);
      transform: translateY(-2px);

      .avatar-img {
        filter: none;
      }
    }

    &[aria-expanded="true"], .active {
      width: 160px;
      height: 160px;
      border: 3px solid $secondary;

      .avatar-img {
        filter: none;
      }
    }

    .avatar-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-size: cover;
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    }
  }
}

.team-member-bio {
  margin-top: map_get($spacers, 4);
  border-radius: 5px;
  background-color: $primary;
  @include fmt-borders;
  opacity: 0;
  @include box-shadow(8);
  transition: 0.3s;

  &.show {
    opacity: 1;
    height: auto;
  }

  &:not(:last-of-type) {
    margin-bottom: map_get($spacers, 4);
  }

  .bio-block {
    display: flex;
    flex-direction: column;

    &:nth-child(odd) {
      @media screen and (min-width: breakpoint-min(lg)) {
        flex-direction: row-reverse;
      }
    }

    &:nth-child(even) {
      text-align: right;

      @media screen and (min-width: breakpoint-min(lg)) {
        flex-direction: row-reverse;
      }
    }

    .block-text {
      > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  .bio-content {
    .block-img {
      border: 2px solid $white;
      @include fmt-borders;
      @include box-shadow(2);
    }
  }
}