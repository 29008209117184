body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .body-overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: $zindex-fixed;
    transition: background-color $transition-timing-normal;
  }

  main {
    margin-top: $navbar-height;
    flex: 1 0 auto;
    overflow: hidden;
    transition: $transition-timing-normal;

    &.blur {
      filter: blur(4px) grayscale(50%);
    }

    @media screen and (max-width: breakpoint-max(md)) {
      margin-top: $navbar-height-mobile;
    }
  }
}

.form-hidden {
  height: 0;
  opacity: 0;
  position: absolute;
}

.bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &--fixed {
    background-attachment: fixed;
  }
}

.bg-img-contain {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-transparent {
  background-color: transparent !important;
}

.img-cover {
  object-fit: cover !important;
}

.img-contain {
  object-fit: contain !important;
}

.content-block {
  padding-top: $content-block-spacer;
  padding-bottom: $content-block-spacer;

  @media screen and (max-width: breakpoint-max(sm)) {
    padding-top: map_get($spacers, 4);
    padding-bottom: map_get($spacers, 4);
  }

  &--lg {
    padding-top: $content-block-spacer-lg;
    padding-bottom: $content-block-spacer-lg;

    @media screen and (max-width: breakpoint-max(sm)) {
      padding-top: map_get($spacers, 5);
      padding-bottom: map_get($spacers, 5);
    }
  }

  &--xl {
    padding-top: $content-block-spacer-xl;
    padding-bottom: $content-block-spacer-xl;

    @media screen and (max-width: breakpoint-max(sm)) {
      padding-top: map_get($spacers, 5);
      padding-bottom: map_get($spacers, 5);
    }
  }
}

@mixin fmt-borders($border-top-left-radius: 10px, $border-top-right-radius: 0, $border-bottom-right-radius: 10px, $border-bottom-left-radius: 0) {
  border-top-left-radius: $border-top-left-radius;
  border-top-right-radius: $border-top-right-radius;
  border-bottom-left-radius: $border-bottom-left-radius;
  border-bottom-right-radius: $border-bottom-right-radius;
}

.anchor {
  display: block;
  visibility: hidden;

  @media screen and (max-width: breakpoint-max(md)) {
    height: $navbar-height-mobile;
    margin-top: calc(#{$navbar-height-mobile} * -1.25)
  }

  @media screen and (min-width: breakpoint-min(md)) {
    height: 95px;
    margin-top: -95px;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}