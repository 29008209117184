main.motorcycleToursLanding {
  .product__tile {
    @media screen and (max-width: breakpoint-max(xs)) {
      height: 300px;
    }

    .tour-tile__content {
      &::after {
        background: linear-gradient(to bottom, rgba(33,47,41,0.5) 0%,rgba(30,87,153,0) 20%,rgba(30,87,153,0) 60%,rgba(33,47,41,0.7) 100%);
      }
    }
  }
}

.tours__year-block {
  .product__tile {
    height: 410px;
  }
}