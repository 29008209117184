@font-face {
  font-family: 'Bebas Neue';
  font-weight: 200;
  src: url('../fonts/BebasNeue-Thin.otf') format("opentype");
}

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 300;
  src: url('../fonts/BebasNeue-Light.otf') format("opentype");
}

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 400;
  src: url('../fonts/BebasNeue-Regular.otf') format("opentype");
}

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 700;
  src: url('../fonts/BebasNeue-Bold.otf') format("opentype");
}