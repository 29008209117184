.collapse-item {
  border: 0;
  border-radius: 0;

  &:not(:last-child) {
    border-bottom: 1px solid transparentize($slate-grey, 0.8);
  }

  a {
    font-size: $font-size-lg;
    text-decoration: none;

    i {
      transition: 0.2s;
    }

    &:hover {
      background-color: inherit;
    }

    &[aria-expanded=true] {
      i {
        color: $primary !important;
        transform: rotate(45deg);
      }
    }
  }

  .card {
    border: 0;
    border-radius: 0;
  }
}