.book-now__cta {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: map_get($spacers, 3);
  z-index: $zindex-fixed;
  @include box-shadow(4);
  transition: 0.3s ease;
}

.tour__booking-options {
  border-width: 2px 0;
  border-color: $rose;
  border-style: solid;

  .tour__booking-option-btn {
    display: flex;
    flex-direction: column;
    border: 2px solid $mid-grey;
    border-radius: 10px;
    padding: map_get($spacers, 3);
    text-align: center;
    text-decoration: none;
    transition: 0.3s;

    &:not(.unavailable) {
      &:hover {
        background-color: $light;
        @include box-shadow(4);
      }

      &[aria-expanded=true] {
        border: 2px solid $rose;
        background-color: $light;
        @include box-shadow(8);
        transform: translateY(-5px);
      }
    }

    &.unavailable {
      opacity: 0.5;
      pointer-events: none;
    }

    h6 {
      color: $fmt-red;
      font-size: $h4-font-size;
      font-weight: bold;
      text-transform: uppercase;
    }

    p {
      color: $dark;

      &:nth-of-type(2) {
        font-weight: bold;
      }
    }

    .booking-option__icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 80px;
      margin-bottom: map_get($spacers, 4);
    }
  }
}

#self-guided-booking-form {
  h5 {
    margin: map_get($spacers, 4) 0;
  }

  input[type=tel], input[type=number], input[type=email],
  input[type=text], textarea {
    @include fmt-borders;
  }
}