.list-block {
  .list-group-item {
    &:first-child {
      border-top: 0;
    }

    .display-4 {
      height: $spacer * 2.875;
      line-height: 1;
    }
  }
}