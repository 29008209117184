#callback-modal {
  .modal-content {
    @include box-shadow(16);

    .modal-header {
      border-bottom: none;
      border-radius: $modal-content-border-radius;

      .modal-img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-top-left-radius: $modal-content-border-radius;
        border-top-right-radius: $modal-content-border-radius;
      }
    }

    .modal-body {
      form {
        #label-success, #label-warning {
          display: none;
        }

        button[type="submit"] {
          font-size: $font-size-base;

          .spinner-border {
            display: none;
            width: $font-size-base;
            height: $font-size-base;
          }
        }
      }
    }
  }
}

.modal-backdrop {
  filter: blur(4px);
}