.cart-form {
  .col--pricing-totals {
    .pricing-totals {
      border-radius: $brand-border-radius;
    }
  }

  .col--cart-summary {
    .cart-summary {
      border-radius: $brand-border-radius;
    }
  }
}