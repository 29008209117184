.pricing-totals {
  .pricing-total {
    display: flex;
    justify-content: space-between;
  }

  .shipping-total {
    font-size: $h1-font-size;
  }

  .grand-total {
    font-size: $h2-font-size;
    font-weight: bold;
  }
}