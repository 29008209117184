.social-banner {
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (min-width: breakpoint-min(lg)) {
    min-height: 300px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($secondary, 0.2);
    z-index: 0;
  }

  .row {
    z-index: 1;
  }

  .social-banner__social-links {
    margin-top: map_get($spacers, 4);
  }

  &__link {
    &:not(:first-of-type) {
      margin-left: map_get($spacers, 5);
    }

    i {
      transition: 0.2s;
    }

    &:hover {
      i {
        color: darken($white, 15%) !important;
      }
    }
  }
}