// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  transition: 0.3s;

  &.btn-shadow {
    @include box-shadow(4);

    &:hover {
      @include box-shadow(8);
    }
  }

  &.btn-fmt {
    padding: map_get($spacers, 2) map_get($spacers, 4);
    @include fmt-borders;
    text-transform: uppercase;
    font-size: $font-size-sm;
    font-weight: 700;

    &:hover {
      @include fmt-borders(0px, 10px, 0px, 10px);
    }

    @media screen and (min-width: breakpoint-min(lg)) {
      min-width: 200px;
    }
    
    @media screen and (max-width: breakpoint-max(xs)) {
      display: block;
    }
  }
}

// Commerce 'Submit payment button'
#confirm-payment {
  position: relative;
  min-height: 39px;
}