.product-overview {
  .overview-body {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: map_get($spacers, 3);
    }

    p:last-child {
      margin-bottom: 0;
    }

    .product-highlights {
      list-style: none;

      @media screen and (min-width: breakpoint-min(lg)) {
        column-count: 2;
      }
    }
  }
}