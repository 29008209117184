
// Brand Colors
//
$midnightgreen: #005979;
$rose: #F45B69;
$fmt-red: #DA2128;
$fmt-red-lighten: lighten($fmt-red, 10%);
$green: #5FAD56;
$red: #BA1F33;
$white: #FFFFFF;
$pale-grey: #FAFAFA;
$mid-grey: #D8D8D8;
$slate-grey: #212529;

// Theme Colors
$primary: $midnightgreen;
$secondary: $fmt-red-lighten;
$light: $pale-grey;
$dark: $slate-grey;
$success: $green;
$alert: $red;
$danger: $red;


$colors: ();
$colors: map_merge(
  (
    "primary":        $primary,
    "secondary":      $secondary,
    "light":          $light,
    "dark":           $dark,
    "success":        $success,
    "danger":         $danger,
    "alert":          $alert,
    "midnight-green": $midnightgreen,
    "rose":           $rose,
    "green":          $green,
    "fmt-red":        $fmt-red,
    "red":            $red,
    "red-lighten":    $fmt-red-lighten,
    "white":          $white,
    "mid-grey":       $mid-grey,
    "slate-grey":     $slate-grey
  ),
  $colors
);


$theme-colors: ();
$theme-colors: map_merge(
  (
    "primary":        $primary,
    "secondary":      $secondary,
    "success":        $success,
    "danger":         $danger,
    "alert":          $alert,
    "midnight-green": $midnightgreen,
    "rose":           $rose,
    "green":          $green,
    "red":            $red,
    "white":          $white,
    "slate-grey":     $slate-grey,
    "slate-grey-opaque": transparentize($slate-grey, 0.9)
  ),
  $theme-colors
);


$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((
      0: 0,
      -1: (-$spacer * .25),
      -2: (-$spacer * .5),
      -3: -$spacer,
      -4: (-$spacer * 1.5),
      -5: (-$spacer * 3),
      -6: (-$spacer * 4.5),
      -7: (-$spacer * 6),
      6: ($spacer * 4.5),
      7: ($spacer * 6)
), $spacers);


// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
(
      20: 20%,
      25: 25%,
      40: 40%,
      50: 50%,
      60: 60%,
      75: 75%,
      80: 80%,
      100: 100%,
      auto: auto
),
$sizes
);


// Content block spacers
$content-block-spacer: 3rem;
$content-block-spacer-lg: 4.5rem;
$content-block-spacer-xl: 6rem;


// Text shadow
$emphasis-text-shadow: 0 0 3px $dark;
$emphasis-text-shadow-display: 0 0 4px transparentize($dark, 0.2);


// Navbar variables
$navbar-height: 75px;
$navbar-height-mobile: 56px;
$navbar-padding-y: $spacer;
$navbar-padding-x: $spacer;
$navbar-nav-link-padding-x: $spacer * 0.75;
$navbar-brand-height-mobile: 11rem;
$navbar-brand-height: 11rem;


// Navbar hamburger variables. Sourced from https://github.com/jonsuh/hamburgers
$hamburger-padding-x: 15px;
$hamburger-padding-y: 15px;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: #000;
$hamburger-layer-border-radius: 4px;
$hamburger-hover-opacity: 0.7;
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;


// Transition timings
$transition-timing-slow: 0.45s;
$transition-timing-normal: 0.3s;
$transition-timing-fast: 0.15s;


// Brand border radius
$brand-border-radius: 5px;
$brand-border-radius-sm: 3px;


// Buttons
$btn-letter-spacing: 0.025rem;
$btn-border-radius: 5px;


// Font families
$font-family-sans-serif:      "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-display:         "Bebas Neue" -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family:        $font-family-sans-serif;


// Img's
$img-border-radius: 5px;


// 'Tile' style cards
$tile-border-radius: 5px;