.featured-tours__grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: $spacer * 0.75;
  min-height: 500px;

  .product__tile {
    width: 100%;
    height: 100%;
    @include fmt-borders;

    @media screen and (max-width: breakpoint-max(sm)) {
      height: 280px;
    }

    &:nth-child(1) {
      grid-column: 8 / 11;
      grid-row: 1 / 3;

      @media screen and (max-width: breakpoint-max(sm)) {
        grid-column: 1 / 11;
        grid-row: 1 / 2;
      }
    }

    &:nth-child(2) {
      grid-column: 4 / 8;
      grid-row: 1 / 2;

      @media screen and (max-width: breakpoint-max(sm)) {
        grid-column: 1 / 11;
        grid-row: 2 / 3;
      }
    }

    &:nth-child(3) {
      grid-column: 1 / 5;
      grid-row: 2 / 3;

      @media screen and (max-width: breakpoint-max(sm)) {
        grid-column: 1 / 11;
        grid-row: 3 / 4;
      }
    }

    &:nth-child(4) {
      grid-column: 1 / 4;
      grid-row: 1 / 2;

      @media screen and (max-width: breakpoint-max(sm)) {
        grid-column: 1 / 11;
        grid-row: 4 / 5;
      }
    }

    &:nth-child(5) {
      grid-column: 5 / 8;
      grid-row: 2 / 3;

      @media screen and (max-width: breakpoint-max(sm)) {
        grid-column: 1 / 11;
        grid-row: 5 / 6;
      }
    }
  }
}