.motorcycle-tours {
  .hero--product-showcase {
    .carousel-slide {
      object-fit: cover;
    }
  }

  .pricing__options {
    .single-room {
      @media screen and (min-width: breakpoint-min(lg)) {
        border-right: 1px solid transparentize($black, 0.9);
      }
    }

    .shared-room {
      @media screen and (min-width: breakpoint-min(lg)) {
        padding-left: map_get($spacers, 4);
      }
    }

    hr {
      border: 1px solid transparentize($black, 0.85);
    }

    .pricing__summary {
      @include fmt-borders;
      @include box-shadow(4);
      transition: 0.2s;

      &:hover {
        @include box-shadow(8);
      }
    }
  }
}