.product__tile {
  min-height: 200px;
  @include fmt-borders;
  @include box-shadow(4);
  transition: 0.25s ease-in-out !important;

  &--fallback {
    opacity: 0.7;

    p {
      margin: 0 auto;
    }

    @media screen and (max-width: breakpoint-max(md)) {
      display: none !important;
    }

    .fallback-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .tour-tile__content {
    position: relative;

    &--fully-booked {
      *:not(.marker--booked) {
        opacity: 0.75;
      }

      .marker--booked {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 2;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: map_get($spacers, -3);
      left: map_get($spacers, -3);
      width: calc(100% + (2 * #{map_get($spacers, 3)}));
      height: calc(100% + (2 * #{map_get($spacers, 3)}));
      @include fmt-borders;
      background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.2) 40%,rgba(0,0,0,0.2) 60%,rgba(0,0,0,0.5) 100%);
      opacity: 0.4;
      z-index: 0;
      transition: 0.3s;

      @media screen and (max-width: breakpoint-max(sm)) {
        opacity: 0.4;
      }
    }

    .badge--skill {
      position: absolute;
      top: 0;
      left: 0;
      padding: map_get($spacers, 1) map_get($spacers, 2);
      border: 1px solid $white;
      background-color: transparentize($white, 0.7);
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
      @include box-shadow(2);
      transition: 0.2s ease-in-out;
      z-index: 1;
    }

    .tour__details {
      transition: 0.4s;
      z-index: 1;
      text-shadow: $emphasis-text-shadow;

      .tile-title {
        font-weight: 600;
      }
    }

    .content-reveal {
      position: absolute;
      bottom: 0;
      left: 0;
      text-shadow: $emphasis-text-shadow;
      opacity: 0;
      transform-origin: bottom center;
      transition: 0.4s;
      z-index: 1;

      p {
        line-height: 1.25;
      }
    }
  }

  &:hover {
    @include box-shadow(8);
    transform: translateY(-2px);

    // For displaying hidden description if present
    &.product__tile--hover {
      .tour-tile__content {
        &::after {
          opacity: 1;
        }
      }

      &.product__tile--fallback {
        background: blue !important;
      }

      .tour__details {
        opacity: 0;
      }

      .content-reveal {
        opacity: 1;
      }
    }

    .badge--skill {
      background-color: $secondary;
    }
  }
}