
// Responsive width, height and max-width properties
@each $prop, $abbrev in (width: w, height: h, max-width: mw) {
  @each $breakpoint in map_keys($grid-breakpoints) {

    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}