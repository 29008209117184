.callback-block {
  position: relative;
  height: 500px;
  box-shadow: inset 0  10px 8px -8px $dark,
              inset 0 -10px 8px -8px $dark;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($white, 0.3);
    z-index: 0;
  }

  .block-content {
    z-index: 1;
  }
}