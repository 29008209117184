.col--cart-summary {
  .cart-items {
    .cart-item {
      padding: map_get($spacers, 4) 0;
      border-bottom: 1px solid transparentize($slate-grey, 0.9);

      &:not(:first-of-type) {
        margin-top: map_get($spacers, 3);
      }

      // Remove item and amend quantity inputs
      .item-controls {
        input[type="number"] {
          width: 60px;
          @include fmt-borders;
        }
      }
    }
  }
}

.item-thumbnail {
  width: 60px;
  height: 60px;
}

#item-summary {
  border-radius: $brand-border-radius;

  @media screen and (max-width: breakpoint-max(md)) {
    display: none !important;
  }

  .item-price-quantity {
    display: flex;
    flex-wrap: wrap;

    p {
      position: relative;

      &:not(:last-of-type) {
        padding-right: map_get($spacers, 3);

        &::after {
          content: '';
          position: absolute;
          right: 0;
          width: 2px;
          height: 100%;
          background-color: $mid-grey;
        }
      }

      &:not(:first-of-type) {
        padding-left: map_get($spacers, 3);
      }
    }
  }
}