.cart-indicator {
  display: flex;
  position: relative;
  transition: 0.3s;

  &--mobile-collapse {
    margin: 0 auto;
  }

  .cart-counter {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $secondary;
    color: $white;
    font-size: $font-size-sm;
    font-weight: bold;
    transform: translate(50%, -50%);
    z-index: 10;
  }
}