main.contact-us {
  .contact-block {
    &:not(:first-of-type) {
      margin-top: map_get($spacers, 5);
    }
  }

  #contact-form {
    input, textarea {
      @include fmt-borders;
    }

    .smallprint {
      a {
        font-weight: 600;
      }
    }
  }

  .address-info {
    p {
      margin-bottom: 0;
    }
  }

  .social-accounts {

  }
}