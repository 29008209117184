.quote-slider {
  #testimonial-carousel {
    .carousel-item {
      .col--image {
        min-height: 400px;

        @media screen and (min-width: breakpoint-min(xl)) {
          min-height: 600px;
        }
      }
    }
  }
}