main.clothing {
  .products--clothing {
    .product--clothing {
      .product-img {
        img {
          max-height: 400px;

          &.fallback-img {
            opacity: 0.3;
            width: 100%;
            height: auto;
            max-width: 400px;
          }
        }
      }
    }
  }

  .hero--product-showcase {
    border-bottom: 5px solid $secondary;

    .form-check {
      .form-check-input:checked ~ .form-check-label {
        background-color: $primary;
        color: $white;
      }
    }

    .owl-item {
      img.carousel-slide {
        background-color: $white;
        padding: map_get($spacers, 4) 0;

        @media screen and (min-width: breakpoint-min(lg)) {
          padding: map_get($spacers, 5) 0;
          width: 75%;
          margin-left: auto;
          transition: 0.3s;
        }

        &.full-width {
          width: 100%;
        }
      }
    }
  }

  .featured-tours-block {
    background-color: $light !important;
  }
}