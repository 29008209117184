.split-blocks {
  .split-block {
    @media screen and (max-width: breakpoint-max(sm)) {
      flex-direction: column;
    }

    &:nth-child(even) {
      @media screen and (min-width: breakpoint-min(md)) {
        flex-direction: row-reverse;
      }
    }

    .block__image {
      @media screen and (max-width: breakpoint-max(sm)) {
        height: 300px;
      }

      @media screen and (min-width: breakpoint-min(xl)) {
        max-height: 500px;
      }
    }
  }
}