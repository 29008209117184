.tour__itinerary {
  display: flex;

  .itinerary-stages {
    .itinerary-stage {
      &:not(:last-of-type) {
        margin-bottom: map_get($spacers, 6);
      }

      .stage__specs {
        display: flex;
        margin-top: map_get($spacers, 2);
        font-size: $font-size-sm;
        font-weight: bold;
        text-transform: uppercase;

        > div {
          display: flex;
          align-items: center;
          color: transparentize($slate-grey, 0.2);

          &:not(:first-of-type) {
            margin-left: map_get($spacers, 3);
          }

          i {
            color: lighten($primary, 7.5%);
          }
        }
      }

      .stage__description {
        p:last-of-type {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        @include fmt-borders;
      }
    }
  }

  .itinerary-nav {
    position: sticky;
    top: 0;
    max-height: 80vh;
    @include fmt-borders;
    overflow: scroll;
    @include box-shadow(2);
    transition: box-shadow 0.3s ease-in-out;

    &.sticky-nav {
      @include box-shadow(8);
    }

    @media screen and (max-width: breakpoint-max(md)) {
      display: none;
    }

    @media screen and (min-width: breakpoint-min(lg)) {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
    }

    .list-group__heading {
      border-top-left-radius: 10px;
    }

    .list-group-items {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 14px;
        left: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 5%,rgba(0,0,0,0.2) 100%);
      }

      .list-group-item {
        position: relative;
        border-radius: 0;
        background-color: transparentize($primary, 0.95);
        border-top-color: $white;
        border-bottom-color: $white;
        border-left: 0;
        border-right: 0;
        z-index: 2;
        overflow: visible;

        &:first-child {
          border-top: 0;
        }

        &.active {
          position: relative;
          background-color: $secondary !important;
          border-color: $white;
          border-left: 0;
          border-right: 0;
          color: $white !important;
          font-weight: bold;

          &::before {
            transform: translate(-100%, 0);
          }
        }
      }
    }

    .list-group__footer {
      border-bottom-right-radius: 10px;
    }
  }

  .sticky-nav {
    position: fixed !important;

    @media screen and (max-width: breakpoint-max(md)) {
      display: none
    }

    @media screen and (min-width: breakpoint-min(md)) {
      top: $navbar-height;
    }
  }
}